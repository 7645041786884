import { createContext, useEffect, useReducer } from "react";
import Reducer from "./Reducer";

const INITIAL_STATE = {
  users: JSON.parse(localStorage.getItem("users")) || null,
  isFetching: false,
  error: false,
};

export const Context1 = createContext(INITIAL_STATE);

export const ContextProvider1 = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("users", JSON.stringify(state.users));
  }, [state.users]);

  return (
    <Context1.Provider
      value={{
        users: state.users,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </Context1.Provider>
  );
};

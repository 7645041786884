import React, { useCallback } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "./Register.css";
import WDCLogo from "../../assests/register/WDClogo.png";
import WDALogo from "../../assests/register/WDAlogo.png";
import Footer from "../../component/Footer/Footer";
import BasicExample from "../../component/Navbar/BasicExample";

import PersonalDetails from "../../component/Registration/PersonalDetails";
import Address from "../../component/Registration/Address";
import FileSubmit from "../../component/Registration/FileSubmit";
import { BaseUrl } from "../../utils/BaseUrl";

const ApplyAwards = () => {
  const [name, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [Institution, setInstitution] = useState("");
  const [streetName, setStreetName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState(null);
  const [Pincode, setPincode] = useState("");
  const [AwardName, setAwardName] = useState("");
  const [valueGender, setvalueGender] = useState("");
  const [Qualification, setQualification] = useState("");
  const [designations, setDesignations] = useState("");
  const [Speciality, setSpeciality] = useState("");
  const [biography, setBiography] = useState("");

  const navigate = useNavigate();

  const personalDetailsfunc = useCallback((data) => {
    const {
      name,
      email,
      age,
      valueGender,
      phoneNumber,
      Institution,
      Quality,
      designations,
      Specialitiies,
      value,
      biography,
    } = data;
    setUsername(name);
    setEmail(email);
    setAge(age);
    setvalueGender(valueGender.value);
    setPhoneNumber(phoneNumber);
    setInstitution(Institution);
    setQualification(Quality.value);
    setDesignations(designations.value);
    setSpeciality(Specialitiies.value);
    setAwardName(value.value);
    setBiography(biography);
  }, []);
  const AddressDetailsfunc = useCallback((data) => {
    const {
      streetName,
      selectedCountry,
      selectedState,
      selectedCity,
      Pincode,
    } = data;
    setCountry(selectedCountry?.name);
    setState(selectedState?.name);
    setCity(selectedCity?.name);
    setStreetName(streetName);
    setPincode(Pincode);
  }, []);
  const FileDetailsFunc = useCallback((data) => {
    const { file, files } = data;
    setFile(file);
    setFiles(files);
  }, []);


  let satisfied = [
    name,
    email,
    phoneNumber,
    age,
    valueGender,
    Institution,
    AwardName,
    streetName,
    country,
    Pincode,
    Qualification,
    Speciality,
    designations,
    biography,
    file,
    files,
  ].every(Boolean);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false && form.checkValidity() === "") {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    !satisfied && alert("Please fill the all value");

    const MAX_FILE_SIZE = 2120; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      alert("File size is greater than 2MB")
      return;
    }
    const fileSizeKiloBytess = files.size / 1024;
    if (fileSizeKiloBytess > MAX_FILE_SIZE) {
      alert("File size is greater than 2MB")
      return;
    }



    const newPost = {
      name,
      email,
      phoneNumber,
      age,
      valueGender,
      Institution,
      AwardName,
      streetName,
      city,
      state,
      country,
      Pincode,
      Qualification,
      Speciality,
      designations,
      biography,
    };


    console.log(newPost)

    if (file) {
      const data = new FormData();
      const filename = file.name;
      data.append("name", filename);
      data.append("file", file);
      newPost.resume = filename;
      try {
        await axios.post(`${BaseUrl}/api/resume`, data);
      } catch (err) { }
    }

    if (files) {
      const data = new FormData();
      const filename = files.name;
      data.append("name", filename);
      data.append("file", files);
      newPost.photo = filename;
      try {
        await axios.post(`${BaseUrl}/api/photo`, data);
      } catch (err) { }
    }

    try {
      const response = await axios.post(`${BaseUrl}/award`, newPost);
      alert("Award Posted Successfull")
      response.data && (window.location.href = "https://worlddentalconference.in/")
    } catch (err) { }
  };





  return (
    <div>
      <BasicExample />
      <div className="headedImage">
        <div className="container-fluid col-10">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-3 col-md-12 col-12">
              <img
                src={WDCLogo}
                alt="logo"
                className="img-fluid register_WDCLogo center-block d-block mx-auto"
              />
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-5 mt-lg-0">
              <p className="para_worldDental text-center">
                Apply For Dental Awards
              </p>
              <h2 className="head_worldDental text-center">REGISTRATION</h2>
            </div>
            <div className="col-lg-3 col-md-12 col-12 mt-5 mt-lg-0">
              <img
                src={WDALogo}
                alt="logo"
                className="img-fluid register_WDCLogo center-block d-block mx-auto"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="mt-4"
        >
          <PersonalDetails
            getDetails={personalDetailsfunc}
            errorMsg1
            errorMsg
          />

          <Address getAddressDetails={AddressDetailsfunc} errorMsg1 errorMsg />

          <FileSubmit getFileDetails={FileDetailsFunc} errorMsg />

          <Button
            type="submit"
            disabled={!satisfied}
            className={
              "mt-3 submit_Form form_register2 shadow-none center-block d-block mx-auto"
            }
          >
            Submit form
          </Button>
        </Form>
      </div>
      <Footer />
    </div>
  );
};

export default ApplyAwards;

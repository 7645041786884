import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminNavbar from "../../component/Navbar/AdminNavbar";
import Footer from "../../component/Footer/Footer";
import { BaseUrl } from "../../utils/BaseUrl";

const DetailAwards = () => {
  const [awards, setAward] = useState([]);
  const [AwardCounts, setAwardCounts] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${BaseUrl}/award`);
        setAward(res.data);
      } catch (err) { }

      try {
        const res = await axios.get(`${BaseUrl}/award/award`);
        setAwardCounts(res.data);
      } catch (err) { }
    };

    fetchData();
  }, []);

  return (
    <div>
      <AdminNavbar />
      <div className="container-fluid col-10 mt-5 pb-5">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-6 col-12">
            <h3 className="Accompany_head mt-lg-0 mt-5">
              <b>Award Person Details</b>
            </h3>
          </div>
          <div className="col-lg-2 col-12"></div>
          <div className="col-lg-4 col-md-12 col-12">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="card ">
                  <div className="card-body">
                    <h1 className="formValid text-center">
                      <b>Total Registered Count:</b> &nbsp; {AwardCounts}
                    </h1>
                    <h1 className="formValid text-center mt-4"></h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {awards.map((item) => {
        return (
          <div key={item._id}>
            <div className="container-fluid col-10  ">
              <div className="accordion mt-5 " id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${item.email}`.slice(0, 9)}
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <h2 className="admin_accordion_head"> {item.email}</h2>
                    </button>
                  </h2>
                  <div
                    id={`${item.email}`.slice(0, 8)}
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                          <p>
                            <b>Full Name:&nbsp;&nbsp;</b>
                            {item.name}
                          </p>
                          <p>
                            <b>Email Id:&nbsp;&nbsp;</b>
                            {item.email}
                          </p>
                          <p>
                            <b>Date of Birth:&nbsp;&nbsp;</b>
                            {item.age}
                          </p>
                          <p>
                            <b>Phone Number:&nbsp;&nbsp;</b>
                            {item.phoneNumber}
                          </p>
                          <p>
                            <b>Gender:&nbsp;&nbsp;</b>
                            {item.gender}
                          </p>
                          <p>
                            <b>Institution Name:&nbsp;&nbsp;</b>
                            {item.Institution}
                          </p>
                          <p>
                            <b>Award Name:&nbsp;&nbsp;</b>
                            {item.AwardName}
                          </p>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                          <p className=" text-start">
                            <b>Street Name:&nbsp;&nbsp;</b>
                            {item.streetName}
                          </p>
                          <p className="text-start">
                            <b>City:&nbsp;&nbsp;</b>
                            {item.city}
                          </p>
                          <p className=" text-start">
                            <b>State:&nbsp;&nbsp;</b>
                            {item.state}
                          </p>
                          <p className=" text-start">
                            <b>Country:&nbsp;&nbsp;</b>
                            {item.country}
                          </p>
                          <p className=" text-start">
                            <b>PinCode:&nbsp;&nbsp;</b>
                            {item.Pincode}
                          </p>
                          <p className=" text-start">
                            <b>Resume CV:&nbsp;&nbsp;</b>
                            <a
                              href={`https://jokarcreationswebstorage.blob.core.windows.net/wdc2024resume/${item.resume}`}
                            >
                              {item.resume}
                            </a>
                          </p>
                          <img
                            src={`https://jokarcreationswebstorage.blob.core.windows.net/wdc2024photos/${item.photo}`}
                            className="img-fluid "
                            style={{ width: "200px", height: "200px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="visible">
        <span className="visibility_none">Speaker Counts: &nbsp; </span>
      </div>

      <Footer />
    </div>
  );
};

export default DetailAwards;

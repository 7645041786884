import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../component/Footer/Footer";
import AdminNavbar from "../../component/Navbar/AdminNavbar";
import Dropdown from "react-bootstrap/Dropdown";
import { BaseUrl } from "../../utils/BaseUrl";

const RegistrationDetail = () => {
  const [state, setState] = useState([]);
  const [states, setStates] = useState([]);
  const [statess, setStatess] = useState([]);
  const [statesss, setStatesss] = useState([]);
  const [Poster, setPoster] = useState([]);

  const [upload, setUplaod] = useState([]);
  const [PosterUpload, setPosterUpload] = useState([]);
  const [SpeakerUpload, setSpeakerUpload] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${BaseUrl}/routes/`);
        setState(res.data);
      } catch (err) { }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDatas = async () => {
      try {
        const res = await axios.get(`${BaseUrl}/routes/countby`);
        setStates(res.data[0].count);
        setStatess(res.data[1].count);
        setPoster(res.data[2].count);
      } catch (err) { }
      try {
        const res = await axios.get(
          `${BaseUrl}/routes/countRegister`
        );
        setStatesss(res.data);
        console.log(res.data)
      } catch (err) { }

      try {
        const res = await axios.get(`${BaseUrl}/routes/countUpload`);
        setUplaod(res.data[1].count);
        setPosterUpload(res.data[2].count);
        setSpeakerUpload(res.data[0].count);
      } catch (err) { }
    };
    fetchDatas();
  });

  return (
    <div>
      <AdminNavbar />

      <div className="container-fluid col-lg-10 col-12 mt-5">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-12 col-12 mt-lg-0 mt-5">
            <h2 className="register_detail_head">Registration Details</h2>
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-3 col-md-12 col-12">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="card ">
                  <div className="card-body">
                    <h1 className="formValid text-center">
                      <b>Total Registered :</b> &nbsp; {statesss}
                    </h1>
                    <h1 className="formValid text-center mt-4"></h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-12">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="card ">
                  <div className="card-body">
                    <h1 className="formValid text-center">
                      <b>Paper Presentation :</b> &nbsp; {statess}
                    </h1>
                    <h1 className="formValid text-center">
                      <b>Uploads :</b> &nbsp; {upload}
                    </h1>
                    <h1 className="formValid text-center mt-4"></h1>
                  </div>
                </div>
                <div className="center-block d-block mx-auto">
                  <p className="text-center mt-4">
                    <Dropdown className="center-block d-block mx-auto">
                      <Dropdown.Toggle
                        className="btn_variant_submitted"
                        id="dropdown-basic"
                      >
                        Submitted Files
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {state.map((item) => {
                          console.log(
                            item.Presentation ===
                            "Paper Presentation for Students" &&
                            item.UploadFile !== null
                          );
                          return (
                            <>
                              {item.Presentation ===
                                "Paper Presentation for Students" &&
                                item.UploadFile !== null && (
                                  <Dropdown.Item
                                    href={`https://jokarcreationswebstorage.blob.core.windows.net/wdc2024abstract/${item.UploadFile}`}
                                  >
                                    {item.email}
                                  </Dropdown.Item>
                                )}
                            </>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-12">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="card ">
                  <div className="card-body">
                    <h1 className="formValid text-center">
                      <b>Poster Presentation :</b> &nbsp; {Poster}
                    </h1>
                    <h1 className="formValid text-center">
                      <b>Uploads :</b> &nbsp; {PosterUpload}
                    </h1>
                    <h1 className="formValid text-center mt-4"></h1>
                  </div>
                </div>
                <div className="center-block d-block mx-auto">
                  <p className="text-center mt-4">
                    <Dropdown className="center-block d-block mx-auto">
                      <Dropdown.Toggle
                        className="btn_variant_submitted"
                        id="dropdown-basic"
                      >
                        Submitted Files
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {state.map((item) => {
                          console.log(item.Presentation);
                          return (
                            <>
                              {item.Presentation ===
                                "Poster Presentation for Students" &&
                                item.UploadFile !== null && (
                                  <Dropdown.Item
                                    href={`https://jokarcreationswebstorage.blob.core.windows.net/wdc2024abstract/${item.UploadFile}`}
                                  >
                                    {item.email}
                                  </Dropdown.Item>
                                )}
                            </>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-12">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="card ">
                  <div className="card-body">
                    <h1 className="formValid text-center">
                      <b>Speaker :</b> &nbsp; {states}
                    </h1>
                    <h1 className="formValid text-center">
                      <b>Uploads :</b> &nbsp; {SpeakerUpload}
                    </h1>
                    <h1 className="formValid text-center mt-4"></h1>
                  </div>
                </div>
                <div className="center-block d-block mx-auto">
                  <p className="text-center mt-4">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="btn_variant_submitted"
                        id="dropdown-basic"
                      >
                        Submitted Files
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {state.map((item) => {
                          console.log(item.Presentation);
                          return (
                            <>
                              {item.Presentation ===
                                "Abstract Submission for Speaker" &&
                                item.UploadFile !== null && (
                                  <Dropdown.Item
                                    href={`https://jokarcreationswebstorage.blob.core.windows.net/wdc2024abstract/${item.UploadFile}`}
                                  >
                                    {item.email}
                                  </Dropdown.Item>
                                )}
                            </>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-12 mt-lg-0 mt-5">
            {state.map((item) => {
              console.log(item.profilePic)
              return (
                <>
                  <div className="accordion mt-5" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#${item.name}`.slice(0, 4)}
                          aria-expanded="true"
                          aria-controls={`${item.name}`.slice(0, 3)}
                        >
                          <h2 className="admin_accordion_head">{item.email}</h2>
                        </button>
                      </h2>
                      <div
                        id={`${item.name}.`.slice(0, 3)}
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-lg-6  col-12">
                              <p>
                                <b>Full Name:&nbsp;&nbsp;</b>
                                {item.name}
                              </p>
                              <p>
                                <b>Email Id:&nbsp;&nbsp;</b>
                                {item.email}
                              </p>
                              <p>
                                <b>Date of Birth:&nbsp;&nbsp;</b>
                                {item.age}
                              </p>
                              <p>
                                <b>Contact Number:&nbsp;&nbsp;</b>
                                {item.phoneNumber}
                              </p>
                              <p>
                                <b>Gender:&nbsp;&nbsp;</b>
                                {item.gender}
                              </p>
                              {item.Designation && (
                                <p>
                                  <b>Designation:&nbsp;&nbsp;</b>
                                  {item.Designation}
                                </p>
                              )}


                              {item.Qualification && (
                                <p>
                                  <b>Qualification:&nbsp;&nbsp;</b>
                                  {item.Qualification}
                                </p>
                              )}

                              {item.Speciality && (
                                <p>
                                  <b>Speciality:&nbsp;&nbsp;</b>
                                  {item.Speciality}
                                </p>
                              )}

                              <p>
                                <b>Presentation Category:&nbsp;&nbsp;</b>
                                {item.Presentation}
                              </p>
                              <p>
                                <b>Subject of Presentation:&nbsp;&nbsp;</b>
                                {item.subjectofPresentation}
                              </p>
                              <p>
                                <b>Biography:&nbsp;&nbsp;</b>
                                {item.biographyFiles}
                              </p>
                              <p>
                                <b>Total Amount Pay:&nbsp;&nbsp;</b>
                                {item.gstamount}
                              </p>
                              <p>
                                <b>Submitted File:&nbsp;&nbsp;</b>
                                <a
                                  href={`https://jokarcreationswebstorage.blob.core.windows.net/wdc2024abstract/${item.UploadFile}`}
                                >
                                  {item.UploadFile}
                                </a>
                              </p>
                            </div>
                            <div className="col-lg-6 col-12">
                              <img
                                src={`https://jokarcreationswebstorage.blob.core.windows.net/wdc2024files/${item.profilePic}`}
                                className="img-fluid  mt-lg-0 mt-2"
                                style={{ width: "200px", height: "200px" }}
                              />
                              <div className="img-fluid ">
                                <p className=" mt-4  text-start">
                                  <b>Institution Name:&nbsp;&nbsp;</b>
                                  {item.Institution}
                                </p>
                                <p className="text-start">
                                  <b>Year of Study:&nbsp;&nbsp;</b>
                                  {item.yearofStudy}
                                </p>
                                <p className=" text-start">
                                  <b>Address:&nbsp;&nbsp;</b>
                                  {item.streetName}
                                </p>
                                <p className=" text-start">
                                  <b>City:&nbsp;&nbsp;</b>
                                  {item.city}
                                </p>
                                <p className=" text-start">
                                  <b>State:&nbsp;&nbsp;</b>
                                  {item.state}
                                </p>
                                <p className=" text-start">
                                  <b>Speaker:&nbsp;&nbsp;</b>
                                  {item.Speak}
                                </p>
                                <p className=" text-start">
                                  <b>Role:&nbsp;&nbsp;</b>
                                  {item.Roles}
                                </p>
                                <p className=" text-start">
                                  <b>Accompany Person:&nbsp;&nbsp;</b>
                                  {item.AccompanyCount}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      <div className="visible">
        <span className="visibility_none">Speaker Counts: &nbsp; {states}</span>
      </div>
      <Footer />
    </div>
  );
};

export default RegistrationDetail;

import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../component/Footer/Footer";
import BasicExample from "../../component/Navbar/BasicExample";
import "./AwardDetail.css";

const AwardDetail = () => {
  return (
    <>

      <BasicExample />
      <div className="center">

        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="award_detailsPage">
              <h2 className="award_head">AWARDS</h2>
              <Link to="/applyaward" className="linkALL">
                <button
                  type="button"
                  className="btn_details1 center-block d-block mx-auto mt-4"
                >
                  Apply For Award
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="container-fluid col-lg-10 col-12 mt-5">
          <div className="row">
            <div className="col-lg-12 col-12">
              <h2 className="award_head_para">
                The World Dental Conference Awards, recognizes and acknowledges
                dentists and individuals associated with dentistry, for their
                outstanding achievements and for maintaining highest standards of
                excellence in research and clinical work. The following awards
                will be presented at the awards ceremony on December 14th 2024
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-12 mt-4">
              <ul>
                <li className="award_para mt-2">
                  BEST DENTAL ADMINISTRATOR-2024
                </li>
                <li className="award_para mt-2">
                  OUTSTANDING DENTIST OF THE YEAR-2024
                </li>
                <li className="award_para mt-2">
                  OUTSTANDING YOUNG DENTIST OF THE YEAR-2024
                </li>
                <li className="award_para mt-2">
                  YOUNG UNDER GRADUATE RESEARCHER AWARD-2024
                </li>
                <li className="award_para mt-2">
                  POST GRADUATE RESEARCHER AWARD-2024
                </li>
                <li className="award_para mt-2">
                  BEST STARTUP CLINIC AWARD-2024
                </li>
                <li className="award_para mt-2">
                  BEST MULTI SPECIALITY PRACTICE-2024
                </li>
                <li className="award_para mt-2">PEDODONTIST OF THE YEAR-2024</li>
                <li className="award_para mt-2">
                  YOUNG PEDODONTIST OF THE YEAR-2024
                </li>
                <li className="award_para mt-2">
                  BEST RESEARCHER IN PEDODONTICS-2024
                </li>
                <li className="award_para mt-2">ORTHODONTIST OF THE YEAR-2024</li>
                <li className="award_para mt-2">
                  YOUNG ORTHODONTIST OF THE YEAR-2024
                </li>
                <li className="award_para mt-2">
                  BEST RESEARCHER IN ORTHODONTICS-2024
                </li>
                <li className="award_para mt-2">
                  BEST RESEARCHER IN ORAL MEDICINE & RADIOLOGY-2024
                </li>
                <li className="award_para mt-2">
                  BEST EDUCATOR - PUBLIC HEALTH DENTISTRY-2024
                </li>
                <li className="award_para mt-2">
                  BEST EDUCATOR - PROSTHODONTICS 2024
                </li>
                <li className="award_para mt-2">
                  BEST EDUCATOR - ORTHODONTICS-2024
                </li>
                <li className="award_para mt-2">
                  BEST SPECIALIST FOR ORAL MEDICINE &<br /> YOUNG ENDODONTIST OF
                  THE YEAR-2024
                </li>
                <li className="award_para mt-2">
                  YOUNG PERIODONTIST OF THE YEAR-2024
                </li>
              </ul>
            </div>

            <div className="col-lg-6 col-md-12 col-12 mt-4">
              <ul>
                <li className="award_para mt-2">
                  BEST RESEARCHER IN PERIODONTICS-2024
                </li>
                <li className="award_para mt-2">ORAL SURGEON OF THE YEAR-2024</li>
                <li className="award_para mt-2">
                  YOUNG ORAL SURGEON OF THE YEAR-2024
                </li>
                <li className="award_para mt-2">
                  BEST RESEARCHER IN ORAL SURGERY-2024
                </li>
                <li className="award_para mt-2">
                  PROSTHODONTIST OF THE YEAR-2024
                </li>
                <li className="award_para mt-2">
                  YOUNG PROSTHODONTIST OF THE YEAR-2024
                </li>
                <li className="award_para mt-2">
                  BEST RESEARCHER IN PROSTHODONTICS-2024
                </li>
                <li className="award_para mt-2">
                  ORAL PATHOLOGIST OF THE YEAR-2024
                </li>
                <li className="award_para mt-2">
                  BEST EDUCATOR - IMPLANTOLOGY-2024
                </li>
                <li className="award_para mt-2">
                  BEST EDUCATOR - ORAL MEDICINE-2024
                </li>
                <li className="award_para mt-2">BEST DENTAL COLLEGE-2024</li>
                <li className="award_para mt-2">RADIOLOGY-2024</li>
                <li className="award_para mt-2">
                  BEST EDUCATOR - PEDODONTICS-2024
                </li>
                <li className="award_para mt-2">
                  YOUNG SPECIALIST IN ORAL MEDICINE & RADIOLOGY-2024
                </li>
                <li className="award_para mt-2">
                  BEST EDUCATOR - ORAL PATHOLOGY-2024
                </li>
                <li className="award_para mt-2">
                  BEST EDUCATOR - ORAL SURGERY-2024
                </li>
                <li className="award_para mt-2">ENDODONTIST OF THE YEAR-2024</li>
                <li className="award_para mt-2">
                  BEST RESEARCHER IN ENDODONTICS-2024
                </li>
                <li className="award_para mt-2">PERIODONTIST OF THE YEAR-2024</li>
              </ul>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-12 col-12">
              <div className="award_backup">
                <h2 className="award_title">
                  ONLY A REGISTERED PARTICIPANT CAN APPLY FOR AN AWARD
                </h2>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>

    </>
  );
};

export default AwardDetail;

export const genderss = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];

export const options = [
  {
    label: "BEST DENTAL ADMINISTRATOR-2024",
    value: "BEST DENTAL ADMINISTRATOR-2024",
  },
  {
    label: "OUTSTANDING DENTIST OF THE YEAR-2024",
    value: "OUTSTANDING DENTIST OF THE YEAR-2024",
  },
  {
    label: "OUTSTANDING YOUNG DENTIST OF THE YEAR-2024",
    value: "OUTSTANDING YOUNG DENTIST OF THE YEAR-2024",
  },
  {
    label: "YOUNG UNDER GRADUATE RESEARCHER AWARD-2024",
    value: "YOUNG UNDER GRADUATE RESEARCHER AWARD-2024",
  },
  {
    label: "POST GRADUATE RESEARCHER AWARD-2024",
    value: "POST GRADUATE RESEARCHER AWARD-2024",
  },
  {
    label: "BEST STARTUP CLINIC AWARD-2024",
    value: "BEST STARTUP CLINIC AWARD-2024",
  },
  {
    label: "BEST MULTI SPECIALITY PRACTICE-2024",
    value: "BEST MULTI SPECIALITY PRACTICE-2024",
  },
  {
    label: "PEDODONTIST OF THE YEAR-2024",
    value: "PEDODONTIST OF THE YEAR-2024",
  },
  {
    label: "YOUNG PEDODONTIST OF THE YEAR-2024",
    value: "YOUNG PEDODONTIST OF THE YEAR-2024",
  },
  {
    label: "BEST RESEARCHER IN PEDODONTICS-2024",
    value: "BEST RESEARCHER IN PEDODONTICS-2024",
  },
  {
    label: "ORTHODONTIST OF THE YEAR-2024",
    value: "ORTHODONTIST OF THE YEAR-2024",
  },
  {
    label: "YOUNG ORTHODONTIST OF THE YEAR-2024",
    value: "YOUNG ORTHODONTIST OF THE YEAR-2024",
  },
  {
    label: "BEST RESEARCHER IN ORTHODONTICS-2024",
    value: "BEST RESEARCHER IN ORTHODONTICS-2024",
  },
  {
    label: "BEST RESEARCHER IN ORAL MEDICINE & RADIOLOGY-2024",
    value: "BEST RESEARCHER IN ORAL MEDICINE & RADIOLOGY-2024",
  },
  {
    label: "BEST EDUCATOR - PUBLIC HEALTH DENTISTRY-2024",
    value: "BEST EDUCATOR - PUBLIC HEALTH DENTISTRY-2024",
  },
  {
    label: "BEST EDUCATOR - PROSTHODONTICS 2023",
    value: "BEST EDUCATOR - PROSTHODONTICS 2023",
  },
  {
    label: "BEST EDUCATOR - ORTHODONTICS-2024",
    value: "BEST EDUCATOR - ORTHODONTICS-2024",
  },
  {
    label:
      "BEST SPECIALIST FOR ORAL MEDICINE & YOUNG ENDODONTIST OF THE YEAR-2024",
    value:
      "BEST SPECIALIST FOR ORAL MEDICINE & YOUNG ENDODONTIST OF THE YEAR-2024",
  },
  {
    label: "YOUNG PERIODONTIST OF THE YEAR-2024",
    value: "YOUNG PERIODONTIST OF THE YEAR-2024",
  },
  {
    label: "BEST RESEARCHER IN PERIODONTICS-2024",
    value: "BEST RESEARCHER IN PERIODONTICS-2024",
  },
  {
    label: "ORAL SURGEON OF THE YEAR-2024",
    value: "ORAL SURGEON OF THE YEAR-2024",
  },
  {
    label: "YOUNG ORAL SURGEON OF THE YEAR-2024",
    value: "YOUNG ORAL SURGEON OF THE YEAR-2024",
  },
  {
    label: "BEST RESEARCHER IN ORAL SURGERY-2024",
    value: "BEST RESEARCHER IN ORAL SURGERY-2024",
  },
  {
    label: "PROSTHODONTIST OF THE YEAR-2024",
    value: "PROSTHODONTIST OF THE YEAR-2024",
  },
  {
    label: "YOUNG PROSTHODONTIST OF THE YEAR-2024",
    value: "YOUNG PROSTHODONTIST OF THE YEAR-2024",
  },
  {
    label: "BEST RESEARCHER IN PROSTHODONTICS-2024",
    value: "BEST RESEARCHER IN PROSTHODONTICS-2024",
  },
  {
    label: "ORAL PATHOLOGIST OF THE YEAR-2024",
    value: "ORAL PATHOLOGIST OF THE YEAR-2024",
  },
  {
    label: "BEST EDUCATOR - IMPLANTOLOGY-2024",
    value: "BEST EDUCATOR - IMPLANTOLOGY-2024",
  },
  {
    label: "BEST EDUCATOR - ORAL MEDICINE-2024",
    value: "BEST EDUCATOR - ORAL MEDICINE-2024",
  },
  { label: "BEST DENTAL COLLEGE-2024", value: "BEST DENTAL COLLEGE-2024" },
  { label: "RADIOLOGY-2024", value: "RADIOLOGY-2024" },
  {
    label: "BEST EDUCATOR - PEDODONTICS-2024",
    value: "BEST EDUCATOR - PEDODONTICS-2024",
  },
  {
    label: "YOUNG SPECIALIST IN ORAL MEDICINE & RADIOLOGY-2024",
    value: "YOUNG SPECIALIST IN ORAL MEDICINE & RADIOLOGY-2024",
  },
  {
    label: "BEST EDUCATOR - ORAL PATHOLOGY-2024",
    value: "BEST EDUCATOR - ORAL PATHOLOGY-2024",
  },
  {
    label: "BEST EDUCATOR - ORAL SURGERY-2024",
    value: "BEST EDUCATOR - ORAL SURGERY-2024",
  },
  {
    label: "ENDODONTIST OF THE YEAR-2024",
    value: "ENDODONTIST OF THE YEAR-2024",
  },
  {
    label: "BEST RESEARCHER IN ENDODONTICS-2024",
    value: "BEST RESEARCHER IN ENDODONTICS-2024",
  },
  {
    label: "PERIODONTIST OF THE YEAR-2024",
    value: "PERIODONTIST OF THE YEAR-2024",
  },
];

export const Qualificationist = [
  { label: "BDS", value: "BDS" },
  { label: "MDS", value: "MDS" },
];

export const designationer = [
  { label: "Assisant Professor", value: "Assisant Professor" },
  { label: "Associate Professor", value: "Associate Professor" },
  { label: "Professor", value: "Professor" },
  { label: "Head of Department", value: "Head of Department" },
  { label: "Principal/Dean", value: "Principal/Dean" },
  { label: "Private Practitioner", value: "Private Practitioner" },
  { label: "Consult", value: "Consult" },
];
export const Specialities = [
  {
    label: "Oral Medicine & Radiology ",
    value: "Oral Medicine & Radiology ",
  },
  { label: "Oral Pathology", value: "Oral Pathology" },
  { label: "Public Health Dentistry", value: "Public Health Dentistry" },
  { label: "Orthodontics", value: "Orthodontics" },
  { label: "Endodontics", value: "Endodontics" },
  { label: "Periodontics", value: "Periodontics" },
  { label: "Pedodontics", value: "Pedodontics" },
  { label: "Prosthodontics", value: "Prosthodontics" },
  {
    label: "Oral & Maxillofacial Surgery",
    value: "Oral & Maxillofacial Surgery",
  },
];

export const Years = [
  { label: "First Year", value: "First Year" },
  { label: "Second Year", value: "Second Year" },
  { label: "Third Year", value: "Third Year" },
  { label: "Final Year", value: "Final Year" },
  { label: "House Surgeon", value: "House Surgeon" },
];

export const PostGraduatedesignationer = [
    { label: "Post Graduate Fellow", value: "Post Graduate Fellow" },
    { label: "Assisant Professor", value: "Assisant Professor" },
    { label: "Associate Professor", value: "Associate Professor" },
    { label: "Professor", value: "Professor" },
    { label: "Head of Department", value: "Head of Department" },
    { label: "Principal/Dean", value: "Principal/Dean" },
    { label: "Private Practitioner", value: "Private Practitioner" },
    { label: "Consultant", value: "Consultant" },
];
  
export const subjectofPresent = [
  { label: "General Dentistry", value: "General Dentistry" },
  { label: "Oral Medicine & Radiology", value: "Oral Medicine & Radiology" },
  { label: "Oral Pathology", value: "Oral Pathology" },
  { label: "Public Health Dentistry", value: "Public Health Dentistry" },
  { label: "Orthodontics", value: "Orthodontics" },
  { label: "Endodontics", value: "Endodontics" },
  { label: "Periodontics", value: "Periodontics" },
  { label: "Pedodontics", value: "Pedodontics" },
  { label: "Prosthodontics", value: "Prosthodontics" },
  {
    label: "Oral & Maxillofacial Surgery",
    value: "Oral & Maxillofacial Surgery",
  },
  { label: "Implantology", value: "Implantology" },
];

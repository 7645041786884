import React, { useState, useRef } from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

import {
  genderss,

} from "../../../utils/constants";

const PersonalDetails = ({ getDetails, errorMsg1, errorMsg, isSuccess }) => {
  const ref = useRef();
  const [name, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [Institution, setInstitution] = useState("");
  const [file, setFile] = useState();
  const [passwordTypes, setpasswordTypes] = useState("password");


  const [valueGender, setvalueGender] = useState("");


  getDetails({
    name,
    email,
    age,
    valueGender: valueGender.value,
    phoneNumber,
    Institution,
    file,
    password
  })
  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const togglePassword = () => {
    if (passwordTypes === "password") {
      setpasswordTypes("text");
      return;
    }
    setpasswordTypes("password");
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-12 mt-4">
          <h2 className=" head_general">Personal Details*</h2>
        </div>
      </div>

      <div className="col-lg-12 col-12 mt-3">
        <div className="form_register center-block d-block mx-auto">
          <Form.Control
            required
            type="text"
            placeholder="Name"
            onChange={(e) => setUsername(e.target.value)}
            className=" shadow-none age_input "
          />
          <Form.Control.Feedback type="invalid">
            Please ente a valid name!
          </Form.Control.Feedback>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-6 ">
          <div className="form_register1 right-block d-block ms-lg-auto">
            <Form.Control
              required
              type="text"
              placeholder="Date of Birth"
              ref={ref}
              onChange={(e) => setAge(e.target.value)}
              onFocus={() => (ref.current.type = "date")}
              onBlur={() => (ref.current.type = "text")}
              className="  age_input shadow-none   "
            />
            <Form.Control.Feedback
              type="invalid"
              className="invalid_feedback1 "
            >
              Please enter a alid Date of Birth
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-lg-6 mt-3 mt-lg-0">
          <div className="form_register1 center-block d-block mx-auto mx-lg-0">
            <Select
              placeholder="Choose a Gender..."
              options={genderss}
              isSearchable
              label={valueGender}
              defaultValue={valueGender}
              required
              onChange={setvalueGender}
            />

            <p className="error-message1 mt-3">{errorMsg1}</p>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-6 mt-3 mt-lg-0">
          <div className="form_register1 right-block d-block ms-lg-auto">
            <Form.Control
              type="number"
              placeholder="Mobile Number"
              onChange={(e) => setPhoneNumber(e.target.value)}
              aria-describedby="inputGroupPrepend"
              required
              className="age_input shadow-none "
            />

            <Form.Control.Feedback type="invalid">
              Please enter a valid Phone Number
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-lg-6 mt-3 mt-lg-0">

          <div className="form_register1 password_type center-block d-block mx-auto mx-lg-0">
            <Form.Control
              type={passwordTypes}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              aria-describedby="inputGroupPrepend"
              required
              className="age_input shadow-none "
            />
            <div onClick={togglePassword} className="absolute_password">
              {passwordTypes === "password" ? (
                <FaRegEyeSlash />
              ) : (
                <>
                  <FaRegEye />
                </>

              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12 col-12">
          <div className="form_register center-block d-block mx-auto">
            <Form.Control
              required
              type="email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              className="age_input shadow-none "
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid Email
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12 ">
          <div className="form_register center-block d-block mx-auto">
            <Form.Control
              required
              type="text"
              placeholder="Clinic Name / College Name / Hospital Name / Others"
              onChange={(e) => setInstitution(e.target.value)}
              className=" shadow-none  age_input"
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid Institution Name
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12">
          <Form.Group className="mb-3 ">
            <div className="fileBlessing mt-4">
              <Form.Control
                type="file"
                id="fileInput"
                placeholder="Upload a Passport Size Photo"
                className="file_matter shadow-none"
                onChange={handleFileChange}
                name="Upload a Passport Photo"
                accept="image/png, image/gif, image/jpeg"
                required
              />
            </div>
            {isSuccess ? (
              <p className="success-message mt-3">Validation successful</p>
            ) : null}
            <p className="error-message mt-3">{errorMsg}</p>
            <p className="infos_para file_setting mt-3">
              <i className="bi bi-info-circle"> </i>Upload photo size
              maximum 2MB
            </p>
          </Form.Group>
        </div>
      </div>




    </>
  );
};

export default PersonalDetails;

import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "./Register.css";
import WDCLogo from "../../assests/register/WDClogo.png";
import WDALogo from "../../assests/register/WDAlogo.png";
import Footer from "../../component/Footer/Footer";
import BasicExample from "../../component/Navbar/BasicExample";

import PersonalDetails from "../../component/Registration/Register/PersonalDetails";
import Address from "../../component/Registration/Register/Address";
import Graduation from "../../component/Registration/Register/Graduation";
import { BaseUrl } from "../../utils/BaseUrl";

const Register = () => {
  const [name, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [Institution, setInstitution] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [file, setFile] = useState(null);
  const [password, setPassword] = useState("");
  const [isFetching, setISFetching] = useState(false);

  const [streetName, setStreetName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [statused, setStatused] = useState("idle")

  const [yearofStudy, setYearofStudy] = useState("");

  const [amount, setAmount] = useState(12000);
  const [pgamount, setPgmount] = useState(20000);
  const [Accompanyfees, setAccompanyfees] = useState(false);
  const [TotalAmounts, setTotalAmounts] = useState("");
  const [TotalAmount, setTotalAmount] = useState("");
  const [BasicAmount, setBasicAmount] = useState("");
  const [gstamount, setGstamount] = useState("");

  const [validated, setValidated] = useState(false);

  const [Modal, setModal] = useState(false);
  const [Modals, setModals] = useState(false);

  const [Roles, setRoles] = useState("Undergraduate Student");
  const [Designation, setDesignation] = useState("");
  const [Qualification, setQualification] = useState("");
  const [Speciality, setSpeciality] = useState("");

  const [errorMsg, setErrorMsg] = useState(false);
  const [errorMsg1, setErrorMsg1] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  const [AccompanyCount, setAccompanyCount] = useState("");
  const [inputFields, setInputFields] = useState([{ emailAddress: '', fullName: '' }])

  const navigate = useNavigate();
  const getPersonalDetails = useCallback((data) => {
    const { name, email, age, valueGender, phoneNumber, Institution, file, password } =
      data;
    setUsername(name);
    setEmail(email);
    setAge(age);
    setGender(valueGender);
    setPhoneNumber(phoneNumber);
    setInstitution(Institution);
    setFile(file);
    setPassword(password)
  }, []);

  const getAddressFunc = useCallback((data) => {
    const { streetName, selectedState, selectedCity } = data;
    setStreetName(streetName);
    setState(selectedState?.name);
    setCity(selectedCity?.name);
  }, []);
  const getGraduationFunc = useCallback((data) => {
    const { Roles, Quality, Qualifications, designations, Specialitiies } =
      data;
    setYearofStudy(Quality);
    setRoles(Roles);
    setQualification(Qualifications);
    setDesignation(designations);
    setSpeciality(Specialitiies);
  }, []);



  useEffect(() => {
    Modal ? setAccompanyfees(inputFields.length * 16000) : setAccompanyfees(0);

    Modal ? setAccompanyCount(inputFields.length) : setAccompanyCount(0);
  }, [
    Roles,
    amount,
    Accompanyfees,
    pgamount,
    TotalAmounts,
    Modal,
    inputFields.length,
  ]);



  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFields = [...inputFields];
    updatedFields[index][name] = value;
    setInputFields(updatedFields);
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { emailAddress: '', fullName: '' }]);
  };

  const handleRemoveFields = (index) => {
    const updatedFields = inputFields.filter((_, i) => i !== index);
    setInputFields(updatedFields);
  };

  useEffect(() => {
    setTotalAmounts(
      Roles === "Undergraduate Student"
        ? amount + Accompanyfees
        : pgamount + Accompanyfees
    );

    setBasicAmount(Roles === "Undergraduate Student" ? amount : pgamount);

    setTotalAmount(TotalAmounts);
  }, [Accompanyfees, Roles, TotalAmounts, amount, pgamount]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.Razorpay) {
        console.log('Razorpay script loaded');
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initPayment = (data) => {
    const options = {
      key: process.env.RAZORPAY_ID,
      amount: data.TotalAmounts,
      currency: data.currency,
      name: name,
      description: "Test Transaction",
      order_id: data.order_id,
      handler: async (response) => {
        try {
          const verifyUrl = `${BaseUrl}/callback`;
          const { data } = await axios.post(verifyUrl, response);
          console.log(data);

          if (data.success) {
            alert("Your registration was successful. Please check your email for the invoice.");
            window.location.reload();
          }
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };

    const razorpayObjects = new window.Razorpay(options);
    razorpayObjects.open();
  };

  useEffect(() => {
    setGstamount(TotalAmounts + (TotalAmounts * 18) / 100)
    // setGstamount(1);
  }, [TotalAmounts]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatused("pending")
    setISFetching(true);

    if (name.length < 2) {
      alert("Please Enter valid Name");
      setStatused("idle")
      return
    }

    if (email.length < 2) {
      alert("Please Enter valid Email");
      setStatused("idle")
      return
    }
    if (phoneNumber.length < 6) {
      alert("Please Enter valid Phone Number");
      setStatused("idle")
      return
    }
    if (age.length < 2) {
      alert("Please Enter valid Date of Birth");
      setStatused("idle")
      return
    }
    if (!file) {
      alert("Please Upload a Photo");
      setStatused("idle")
      return
    }
    if (!gender) {
      alert("Please Select Gender");
      setStatused("idle")
      return
    }
    if (Institution.length < 6) {
      alert("Please Enter Valid Institution");
      setStatused("idle")
      return
    }
    if (streetName.length < 2) {
      alert("Please Enter Valid Street Name");
      setStatused("idle")
      return
    }
    if (city === undefined) {
      alert("Please Enter Valid City");
      setStatused("idle")
      return
    }
    if (state === undefined) {
      alert("Please Enter Valid State");
      setStatused("idle")
      return
    }
    if (Roles === "") {
      alert("Please Select a Undergraduate Student/Postgraduate Student & Dentists*");
      setStatused("idle")
      return
    }


    if (password.length < 6) {
      alert("Password must be six letters");
      setStatused("idle")
      return
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false && form.checkValidity() === "") {
      event.preventDefault();
      event.stopPropagation();
    }
    const MAX_FILE_SIZE = 2120; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      alert("File size is greater than 2MB")
      return;
    }

    setValidated(true);
    const newPost = {
      name,
      email,
      phoneNumber,
      age,
      gender,
      Institution,
      yearofStudy,
      streetName,
      password,
      city,
      state,
      Roles,
      Qualification,
      Speciality,
      Designation,
      AccompanyCount,
      TotalAmounts,
      BasicAmount,
      gstamount
    };
    if (file) {
      const data = new FormData();
      const filename = file.name;
      data.append("name", filename);
      data.append("file", file);
      newPost.profilePic = filename;
      try {
        await axios.post(`${BaseUrl}/api/upload`, data);
      } catch (err) {
        return err;
      }
    }

    try {
      const response = await axios.post(`${BaseUrl}/major`, { inputFields, email });
      console.log("Response data:", response.data);
      setStatused("success")
      setISFetching(false);
    } catch (error) {
      console.error("Error submitting form:", error.response?.data || error.message);
      setStatused("success")
      alert("Failed to submit the form.");
      setISFetching(false);
    }
    try {

      const response = await axios.post(`${BaseUrl}/payment`, newPost);
      const orderData = response.data;
      console.log(orderData);
      initPayment(orderData);
    } catch (err) {
      console.log(err);
    }
  };

  const ModalOpener = (e) => {
    if (e.target.checked) {
      setModal(true);
    } else {
      setModal(false);
    }
  };

  const ModalOpener1 = (e) => {
    if (e.target.checked) {
      setModals(true);
    } else {
      setModals(false);
    }
  };

  console.log(yearofStudy)

  return (

    <>
      <BasicExample />

      {statused === "pending" && <div class="background_loader" >
        <div class="loader"></div>
        <p style={{ fontWeight: "bold", color: "#fff" }}>Loading Please Wait....</p>
      </div>}

      <div className="center">

        <div className="headedImage">
          <div className="container-fluid col-10">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-3 col-md-12 col-12">
                <img
                  src={WDCLogo}
                  alt=""
                  className="img-fluid register_WDCLogo center-block d-block mx-auto"
                />
              </div>
              <div className="col-lg-6 col-md-12 col-12 mt-5 mt-lg-0">
                <p className="para_worldDental text-center">
                  World Dental Conference 2024
                </p>
                <h2 className="head_worldDental text-center">REGISTRATION</h2>
              </div>
              <div className="col-lg-3 col-md-12 col-12 mt-5 mt-lg-0">
                <img
                  src={WDALogo}
                  alt=""
                  className="img-fluid register_WDCLogo center-block d-block mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-12 col-12">
            <h2 className=" head_general">Dear delegates </h2>
            <li className="head_para my-lg-3 my-3 d-flex">
              Until August 20th , all payments may be made through NEFT transfer.
              Once transferred, please send the payment confirmation screenshot
              via WhatsApp to 9884042526.
            </li>
            <li className="head_para my-lg-3 my-3 d-flex flex-column">
              The AC details are given below.
              <p className="my-lg-4 my-3" style={{ fontWeight: "800" }}>
                WORLD DENTISTS ASSOCIATION
              </p>
              <ul className="bankDetailList">
                <li className="bankDetailitems">
                  <p>BANK : INDIAN OVERSEAS BANK</p>
                </li>
                <li className="bankDetailitems">
                  <p>BRANCH :MOGAPPAIR EAST</p>
                </li>
                <li className="bankDetailitems">
                  <p>ACCOUNT NUMBER :169502000001999</p>
                </li>
                <li className="bankDetailitems">
                  <p>IFSC CODE : IOBA0001695</p>
                </li>
              </ul>
            </li>
          </div>
        </div>
        <div className="container mt-4">
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="mt-4"
          >
            <PersonalDetails
              getDetails={getPersonalDetails}
              errorMsg={errorMsg}
              errorMsg1={errorMsg1}
            />
            <Address getAddressDetails={getAddressFunc} errorMsg={errorMsg1} />
            <Graduation
              getGraduationDetails={getGraduationFunc}
              errorMsg1={errorMsg1}
              isSuccess={isSuccess}
            />

            <div className="row mt-5">
              <div className="col-lg-12 mt-4">
                <Form.Group
                  className="mb-3 form_register_accompany shadow-none center-block d-block mx-auto"
                  controlId="formBasicCheckbox"
                >
                  <Form.Check
                    type="checkbox"
                    label="Accompany Person"
                    onClick={ModalOpener}
                    className="accompany_bold"
                    required
                  />
                </Form.Group>
              </div>
            </div>

            {Modal
              ? inputFields.map((field, index) => (
                <div key={index}>
                  <div className="row mt-5 mt-lg-3">
                    <div className="col-lg-6 ">
                      <div className="form_register1 right-block d-block ms-lg-auto">
                        <input
                          type="email"
                          name="emailAddress"
                          className="form-control shadow-none  age_input"
                          placeholder="Full Name"
                          value={field.emailAddress}
                          onChange={(e) => handleChange(index, e)}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 mt-3 mt-lg-0">
                      <div className="form_register1 center-block d-block mx-auto mx-lg-0">
                        <input
                          type="text"
                          name="fullName"
                          value={field.fullName}
                          className="form-control  shadow-none age_input"
                          placeholder="Email Address"
                          onChange={(e) => handleChange(index, e)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-6 col-6">
                      {inputFields.length !== 1 ? (
                        <button
                          className="btn btn-outline-danger form_register2 shadow-none right-block d-block ms-auto"
                          onClick={() => handleRemoveFields(index)}
                        >
                          Remove Person
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-6 col-6">
                      <button
                        type="button"
                        className="btn btn-outline-success  form_register2 shadow-none"
                        onClick={handleAddFields}
                      >
                        Add Person
                      </button>
                    </div>
                  </div>
                </div>
              ))
              : ""}

            <div className="row mt-5">
              <div className="col-lg-6 col-12 ">
                <div className=" form_register1 right-block d-block ms-auto">
                  <label className="ug_amount">UG Amount</label>
                  <input
                    type="text"
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount.toLocaleString()}
                    name="amountug"
                    className=" form-control  shadow-none age_input mt-4"
                    placeholder="Amount in UG"
                    disabled
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                <div className="form_register1">
                  <label className="ug_amount">PG & Dentist Amount</label>
                  <input
                    type="text"
                    onChange={(e) => setPgmount(e.target.value)}
                    value={pgamount.toLocaleString()}
                    name="amountug"
                    className="form-control mt-4 shadow-none age_input"
                    placeholder="Amount in PG"
                    disabled
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12 mt-4">
                <div className="form_register1 right-block d-block ms-auto">
                  <label className="ug_amount">Accompany Person Amount</label>
                  <input
                    disabled
                    type="text"
                    onChange={(e) => setAccompanyfees(e.target.value)}
                    value={Accompanyfees.toLocaleString()}
                    name="amountug"
                    className="form-control  shadow-none age_input mt-4"
                    placeholder="Amount in Accompany Person"
                    required
                  />
                </div>
              </div>

              <div className="col-lg-6 col-12 mt-4">
                <div className="form_register1">
                  <label className="ug_amount">Total Amount</label>
                  <input
                    type="text"
                    onChange={(e) => setTotalAmounts(e.target.value)}
                    value={gstamount.toLocaleString()}
                    name="amountug"
                    disabled
                    className="form-control  shadow-none age_input mt-4"
                    placeholder="TotalAmount"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-12">
                <Form.Group
                  className="mb-3 form_register_accompany shadow-none center-block d-block mx-auto"
                  controlId="formBasicCheckbox1"
                >
                  <Form.Check
                    type="checkbox"
                    label="Ensure that you have filled and submitted all details"
                    onClick={ModalOpener1}
                    required
                  />
                </Form.Group>
              </div>
            </div>


            <Button
              type="submit"
              disabled={!Modals}
              className="mt-3 submit_Form form_register2 shadow-none center-block d-block mx-auto"
            >
              Register and Pay
            </Button>
          </Form>

          <div className="row mt-5">
            <div className="col-lg-12 col-12">
              <h2 className=" head_general">Cancellation Policy</h2>

              <li className="head_para mt-lg-4 mt-3 d-flex">
                <p>• </p>
                <p className="mx-3">
                  If request for cancellation is done, before 12.00 pm of December
                  1 2024", then only 50 percent of the registration amount paid
                  (without the GST amount) will be refunded.
                  <br />
                </p>
              </li>

              <li className="head_para mt-lg-2 mt-3 d-flex">
                <p>•</p>{" "}
                <p className="mx-3">
                  {" "}
                  If the request for cancellation comes after 1st of December
                  2024, 12.00 pm, there will be no refund of the amount paid,
                  (including GST).
                </p>
              </li>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Register;

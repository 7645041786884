import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl } from "../utils/BaseUrl";


const initialState = {
  LoginData: [],
  AdminData: {},
  status: "idle",
  error: null,
};

export const LoginUser = createAsyncThunk(
  "LoginSlice/loginuser",
  async (data, { rejectWithValue }) => {
    console.log(data);

    try {
      const res = await axios.post(`${BaseUrl}/router/login`, {
        email: data.email,
        password: data.password,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const LoginAdmin = createAsyncThunk(
  "LoginSlice/loginadmin",
  async (data, { rejectWithValue }) => {
    console.log(data);

    try {
      const res = await axios.post(`${BaseUrl}/router/adminlogin`, {
        email: data.email,
        password: data.password,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const LoginSlice = createSlice({
  name: "LoginSlice",
  initialState,
  reducer: {
    logouts: (state, action) => {
      state.LoginData = [];
      state.status = "idle";
      state.error = null
    },
    adminLogout: (state, action) => {
      state.AdminData = {};
      state.status = "idle";
      state.error = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(LoginUser.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(LoginUser.fulfilled, (state, action) => {
        console.log(action.payload);
        state.LoginData = [action.payload];
        state.status = "success";
      })
      .addCase(LoginUser.rejected, (state, action) => {
        console.log(action.error);
        state.status = "failed";
        state.error = action.error;
      })
      .addCase(LoginAdmin.pending, (state) => {
        state.status = "Loading";
      })
      .addCase(LoginAdmin.fulfilled, (state, action) => {
        state.AdminData = Object(action.payload);
        state.status = "success";
      })
      .addCase(LoginAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      });
  },
});

export const { logouts, adminLogout } = LoginSlice.actions;

export const usersId = (state) => state.LoginSlice?.LoginData[0]?.details?._id;


export default LoginSlice.reducer;

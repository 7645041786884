import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { State, City } from "country-state-city";

import Select from "react-select";

const Address = ({ getAddressDetails, errorMsg1 }) => {
  const [streetName, setStreetName] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);


  getAddressDetails({
    streetName,
    selectedState,
    selectedCity,
  });
 
  return (
    <div>
     <div className="row mt-5">
            <div className="col-lg-12 col-12">
              <h2 className=" head_general">Residential Address*</h2>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-12 col-12">
              <div className="form_register center-block d-block mx-auto">
                <Form.Control
                  required
                  type="text"
                  placeholder="Street Name"
                  onChange={(e) => setStreetName(e.target.value)}
                  className="age_input shadow-none "
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid Street Name!
                </Form.Control.Feedback>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-6 ">
              <div className="form_register1 right-block d-block ms-lg-auto">
                <Select
                  placeholder="Choose a State..."
                  options={State?.getStatesOfCountry("IN")}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedState}
                  onChange={(item) => {
                    setSelectedState(item);
                  }}
                />

                <p className="error-message1 mt-3">{errorMsg1}</p>
              </div>
            </div>

            <div className="col-lg-6 mt-3 mt-lg-0">
              <div className="form_register1 center-block d-block mx-auto mx-lg-0">
                <Select
                  placeholder="Choose a City..."
                  options={City.getCitiesOfState(
                    selectedState?.countryCode,
                    selectedState?.isoCode
                  )}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCity}
                  onChange={(item) => {
                    setSelectedCity(item);
                  }}
                />
                <p className="error-message1 mt-3">{errorMsg1}</p>
              </div>
            </div>
          </div>
    </div>
  );
};

export default Address;

import React from "react";
import BasicExample from "../component/Navbar/BasicExample";
import "./Commitee.css";
import oneman from "../assests/commitee/one.png";
import two from "../assests/commitee/two.png";
import three from "../assests/commitee/three.png";
import four from "../assests/commitee/four.png";
import five from "../assests/commitee/five.png";
import six from "../assests/commitee/six.png";
import seven from "../assests/commitee/seven.png";
import eight from "../assests/commitee/eight.png";
import nine from "../assests/commitee/nine.png";
import ten from "../assests/commitee/ten.png";
import eleven from "../assests/commitee/eleven.png";
import twelve from "../assests/commitee/twelve.png";
import Footer from "../component/Footer/Footer";
import ones from "../assests/commitee/ones.png";
import twos from "../assests/commitee/twos.png";
import threes from "../assests/commitee/threes.png";
import fours from "../assests/commitee/fours.png";

const Committe = () => {
  return (
    <div className="center">
      <BasicExample />

      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="commitee_banner">
            <h2 className="commitee_head">Committee</h2>
          </div>
        </div>
      </div>

      <div className="container-fluid col-lg-10 col-12">
        <div className="row">
          <div className="col-lg-12 col-12 mt-5">
            <h2 className="commitee_head_organize">
              Core Organizing Committee
            </h2>
            <p className="commitee_para mt-4">
              The goal of the WDC 2024 is to present the latest and the best in
              scientific research and clinic cases in all specialties in
              Dentistry. It will also provide an international dias for dentists
              from all over the world to exchange ideas, knowledge and encourage
              networking with similar specialists. World-renowned experts from
              different countries will present Guest Lectures, and Keynote
              Lectures on a wide range of topics that are of particular interest
              to the dental speciality. This year we have introduced a special
              presentation category, Rising Star for dentists who have less than
              five years of experience after completion of Post Graduation. In
              addition to this there will be a competitive paper and poster
              presentation open to dental students (Under Graduates, Post
              Graduates).
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-12 mt-5">
            <h2 className="commitee_head_organize">
              Organizing Committee Members
            </h2>
            <p className="commitee_para mt-4">
              When organizing a world dental conference, the committee members
              play a crucial role in ensuring the event's success. The
              organizing committee is responsible for managing different aspects
              of the conference, such as scheduling, logistics, marketing, and
              finance.
            </p>
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-4 col-md-6 col-12 mt-lg-5 mt-4">
            <img
              src={oneman}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img src={two} className="img-fluid center-block d-block mx-auto" />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={three}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-lg-5 mt-4">
            <img
              src={four}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={five}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img src={six} className="img-fluid center-block d-block mx-auto" />
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-lg-5 mt-4">
            <img
              src={ones}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={threes}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={seven}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-lg-5 mt-4">
            <img
              src={twos}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={fours}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={eight}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-lg-5 mt-4">
            <img
              src={eleven}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img src={ten} className="img-fluid center-block d-block mx-auto" />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={nine}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>

          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-5">
            <img
              src={twelve}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Committe;

import React from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { Link } from "react-router-dom";
import FooterLogo from "../../assests/WDClogo.png";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer mt-5">
      <div className="container-fluid col-lg-12 col-12 mt-4">
        <div className="row footer_border">
          <div className="col-lg-3 col-12">
            <img
              src={FooterLogo}
              className="img-fluid right-block d-block ms-lg-auto center-block d-block mx-auto footer_logo"
            />
          </div>
          <div className="col-lg-6 col-12">
            <div className="d-lg-flex justify-content-between mt-lg-3 mt-5">
              <Link to="/" className="linkALL">
                <p className="quick_para">Home</p>
              </Link>
              <Link to="/commitee" className="linkALL">
                <p className="quick_para ">Commitee</p>
              </Link>

              <Link to="/registration" className="linkALL">
                <p className="quick_para">Registration</p>
              </Link>

              <Link to="/abstract" className="linkALL">
                <p className="quick_para">Abstract Submission</p>
              </Link>

              <Link to="/speakers" className="linkALL">
                <p className="quick_para">Speakers</p>
              </Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-12 mt-lg-0 mt-5">
            <Link to="/contact" className="linkALL ">
              <p className="text-center mt-3 contact_para mb-lg-5 mb-5">
                Contact Us
              </p>
            </Link>

            {/* <p className='quick_link mt-lg-4 mt-5'>
        Address
            </p> */}
            {/* <p className='quick_para mt-lg-2 mt-4'>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
             Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
            </p> */}
          </div>
        </div>
        <div>
          <div className="col-lg-12">
            <p className="quick_para mt-4">
              All Rights Reserved © 2024 World Dental Conference
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

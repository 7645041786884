import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../../component/Navbar/AdminNavbar";
import Footer from "../../component/Footer/Footer";
import CountAccompany from "./CountAccompany";
import { BaseUrl } from "../../utils/BaseUrl";

const Accopany = () => {
  const navigate = useNavigate();

  const [states, setStates] = useState([]);
  const [Accompany, setAccompany] = useState([]);
  const [Accompanynumber, setAccompanynumber] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${BaseUrl}/major`);
        setStates(res.data);
      } catch (err) { }

      try {
        const res = await axios.get(`${BaseUrl}/major/email`);
        setAccompany(res.data);
      } catch (err) { }

      try {
        const res = await axios.get(`${BaseUrl}/major/emails`);
        setAccompanynumber(res.data[0].totalCount);
      } catch (err) { }
    };

    fetchData();
  }, []);

  console.log();

  return (
    <div>
      <AdminNavbar />

      <div className="container-fluid col-10 mt-5 ">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-12 col-12">
            <h3 className="Accompany_head mt-lg-0 mt-5">
              <b>Accompany Person Details</b>
            </h3>
          </div>
        </div>

        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="card ">
                  <div className="card-body">
                    <h1 className="formValid text-center">
                      <b>Number of Person with Accompany:</b> &nbsp; {Accompany}
                    </h1>
                    <h1 className="formValid text-center mt-4"></h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="card ">
                  <div className="card-body">
                    <h1 className="formValid text-center">
                      <b>Total Accompany:</b> &nbsp; {Accompanynumber}
                    </h1>
                    <h1 className="formValid text-center mt-4"></h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid col-10 mt-4">
        <div className="row">
          <div className="col-lg-12 col-12">
            {states.map((item) => {
              return (
                <div
                  className="accordion mt-5"
                  id="accordionExample"
                  key={item._id}
                >
                  <div className="accordion-item">
                    <h2 className="admin_accordion_head" id="headingOne">
                      <button
                        className="accordion-button admin_accordion_head"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${item.email}`.slice(0, 5)}
                        aria-expanded="true"
                        aria-controls={`${item.email}`.slice(0, 4)}
                      >
                        {item.email}
                      </button>
                    </h2>
                    <div
                      id={`${item.email}`.slice(0, 4)}
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      {item.inputFields.map((type) => {
                        return (
                          <>
                            <div className="accordion-body">
                              <p>
                                <b>Name: </b>&nbsp;&nbsp; {type.fullName}
                              </p>
                              <p>
                                <b>Email :</b>&nbsp;&nbsp; {type.emailAddress}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="visible">
        <span className="visibility_none">Speaker Counts: &nbsp; </span>
      </div>
      {/* <CountAccompany /> */}
      <Footer />
    </div>
  );
};

export default Accopany;

// import { getCode, getName } from "country-list";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { useEffect, useState } from "react";

export default function App() {
  const options = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];

  const designation = [
    { label: "Student", values: "Student" },
    { label: "Assisant Professor", values: "Assisant Professor" },
    { label: "Associate Professor", values: "Associate Professor" },
    { label: "Professor", values: "Professor" },
    { label: "Head of Department", values: "Head of Department" },
    { label: "Principal/Dean", values: "Principal/Dean" },
    { label: "Private Practitioner", values: "Private Practitioner" },
    { label: "Consult", values: "Consult" },
  ];

  const designation1 = [
    { label: "Student", value: "Student" }, 
    { label: "Assisant Professor", value: "Assisant Professor" },
    { label: "Associate Professor", value: "Associate Professor" },
    { label: "Professor", value: "Professor" },
    { label: "Head of Department", value: "Head of Department" },
    { label: "Principal/Dean", value: "Principal/Dean" },
    { label: "Private Practitioner", value: "Private Practitioner" },
    { label: "Consult", value: "Consult" },
  ];

  const [designations, setDesignation] = useState("");

  const [designationSystem, setDesignationSystem] = useState("");

  useEffect(() => {
    setDesignation(designationSystem.values);
  });

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [country, setCountry] = useState("");
  useEffect(() => {
    setCountry(selectedCountry?.name);
  }, [selectedCountry, selectedState, selectedCity]);

  return (
    <div>
      <Select
        placeholder="Choose a Country ..."
        options={Country.getAllCountries()}
        getOptionLabel={(options) => {
          return options["name"];
        }}
        getOptionValue={(options) => {
          return options["name"];
        }}
        value={selectedCountry}
        onChange={(item) => {
          setSelectedCountry(item);
        }}
      />

      <Select
        placeholder="Choose a Gender..."
        options={designation}
        isSearchable
        label={designationSystem}
        defaultValue={designationSystem}
        required
        onChange={setDesignationSystem}
      />
    </div>
  );
}

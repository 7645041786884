import React, { useState, useRef } from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";

import {
  genderss,
  options,
  Qualificationist,
  designationer,
  Specialities,
} from "../../utils/constants";

const PersonalDetails = ({ getDetails, errorMsg1, errorMsg }) => {
  const ref = useRef();
  const [name, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [Institution, setInstitution] = useState("");
  const [Quality, setQuality] = useState("");
  const [designations, setDesignations] = useState("");
  const [Specialitiies, setSpecialitiies] = useState("");
  const [value, setValue] = useState("");
  const [biography, setBiography] = useState("");
  const [valueGender, setvalueGender] = useState("");


  getDetails({
    name,
    email,
    age,
    valueGender,
    phoneNumber,
    Institution,
    Quality,
    designations,
    Specialitiies,
    value,
    biography,
  })

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-12 mt-4">
          <h2 className=" head_general">Personal Details*</h2>
        </div>
      </div>

      <div className="col-lg-12 col-12">
        <div className="form_register center-block d-block mx-auto">
          <Form.Control
            required
            type="text"
            placeholder="Name"
            onChange={(e) => setUsername(e.target.value)}
            className=" shadow-none age_input "
          />
          <Form.Control.Feedback type="invalid">
            Please ente a valid name!
          </Form.Control.Feedback>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-6 ">
          <div className="form_register1 right-block d-block ms-lg-auto">
            <Form.Control
              required
              type="text"
              placeholder="Date of Birth"
              ref={ref}
              onChange={(e) => setAge(e.target.value)}
              onFocus={() => (ref.current.type = "date")}
              onBlur={() => (ref.current.type = "text")}
              className="  age_input shadow-none   "
            />
            <Form.Control.Feedback
              type="invalid"
              className="invalid_feedback1 "
            >
              Please enter a alid Date of Birth
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-lg-6 mt-3 mt-lg-0">
          <div className="form_register1 center-block d-block mx-auto mx-lg-0">
            <Select
              placeholder="Choose a Gender..."
              options={genderss}
              isSearchable
              label={valueGender}
              defaultValue={valueGender}
              required
              onChange={setvalueGender}
            />

            <p className="error-message1 mt-3">{errorMsg1}</p>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="form_register center-block d-block mx-auto">
            <Form.Control
              type="number"
              placeholder="Mobile Number"
              onChange={(e) => setPhoneNumber(e.target.value)}
              aria-describedby="inputGroupPrepend"
              required
              className="age_input shadow-none "
            />

            <Form.Control.Feedback type="invalid">
              Please enter a valid Phone Number
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12 col-12">
          <div className="form_register center-block d-block mx-auto">
            <Form.Control
              required
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              className="age_input shadow-none "
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid Email
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12 ">
          <div className="form_register center-block d-block mx-auto">
            <Form.Control
              required
              type="text"
              placeholder="Clinic Name / College Name / Hospital Name / Others"
              onChange={(e) => setInstitution(e.target.value)}
              className=" shadow-none  age_input"
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid Institution Name
            </Form.Control.Feedback>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-6 mt-3 mt-lg-0 ">
          <div className="form_register1 right-block d-block ms-auto ">
            <Select
              placeholder="Qualification..."
              options={Qualificationist}
              isSearchable
              label={Quality}
              defaultValue={Quality}
              required
              onChange={setQuality}
            />
            <p className="error-message1 mt-3">{errorMsg1}</p>
          </div>
        </div>

        <div className="col-lg-6 mt-3 mt-lg-0">
          <div className="form_register1">
            <Select
              placeholder="Designation..."
              options={designationer}
              isSearchable
              label={designations}
              defaultValue={designations}
              required
              onChange={setDesignations}
            />
            <p className="error-message1 mt-3">{errorMsg1}</p>
          </div>
        </div>

        <div className="col-lg-6 mt-3 mt-lg-0">
          <div className="form_register1 right-block d-block ms-auto">
            <Select
              placeholder="Speciality..."
              options={Specialities}
              isSearchable
              label={Specialitiies}
              defaultValue={Specialitiies}
              required
              onChange={setSpecialitiies}
            />
            <p className="error-message1 mt-3">{errorMsg1}</p>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12 ">
          <div className="form_register center-block d-block mx-auto">
            <Select
              placeholder="Select a Award..."
              options={options}
              isSearchable
              label={value}
              defaultValue={value}
              required
              onChange={setValue}
            />
            <p className="error-message1 mt-3">{errorMsg1}</p>
            <Form.Control.Feedback type="invalid">
              Please enter a Award Name
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="col-lg-12 mt-3 mt-lg-3">
          <div className="form_register1 center-block d-block mx-auto">
            <Form.Group className="mb-3 ">
              <textarea
                className="form-control textarea_abstract"
                onChange={(e) => setBiography(e.target.value)}
                placeholder="Enter Your Biography"
                id="floatingTextarea"
              ></textarea>
              <p className="error-message mt-3">{errorMsg}</p>

              <p className="infos_para  mt-3">
                <i className="bi bi-info-circle"> </i>Not more than 250 words
              </p>
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;

import React, { useState } from "react";
import Form from "react-bootstrap/Form";

const FileSubmit = ({ getFileDetails, errorMsg }) => {
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false)

  getFileDetails({ file, files });

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFiles(event.target.files[0]);
      setIsSuccess(true)
    }
  };
  return (
    <div>
      <div className="row mt-5">
        <div className="col-lg-12 col-12">
          <h2 className=" head_general">Submission*</h2>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12">
          <Form.Group className="mb-3 ">
            <div className="fileBlessing mt-4">
              <Form.Control
                type="file"
                id="fileInput"
                placeholder="hii"
                className="file_matter shadow-none "
                onChange={(e) => setFile(e.target.files[0])}
                accept="application/pdf"
              />
            </div>

            <p className="error-message mt-3">{errorMsg}</p>
            <p className="infos_para file_setting mt-3">
              <i className="bi bi-info-circle"> </i>Upload Your Recent CV
              maximum 2MB
            </p>
          </Form.Group>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12">
          <Form.Group className="mb-3 ">
            <div className="fileBlessing mt-4">
              <Form.Control
                type="file"
                id="fileInput"
                className="file_matter shadow-none "
                onChange={handleFileChange}
                name="Upload a Passport Photo"
                accept="image/png, image/gif, image/jpeg"
                required
              />
            </div>

            <p className="error-message mt-3">{errorMsg}</p>
            <p className="infos_para file_setting mt-3">
              <i className="bi bi-info-circle"> </i>Upload Your Recent Passport
              size Photo maximum 2MB
            </p>
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default FileSubmit;

import React, { useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../utils/BaseUrl';

const AccompanyPerson = () => {
    const [inputFields, setInputFields] = useState([{ emailAddress: '', fullName: '' }]);
    const [email, setEmail] = useState("");

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const updatedFields = [...inputFields];
        updatedFields[index][name] = value;
        setInputFields(updatedFields);
    };

    const handleAddFields = () => {
        setInputFields([...inputFields, { emailAddress: '', fullName: '' }]);
    };

    const handleRemoveFields = (index) => {
        const updatedFields = inputFields.filter((_, i) => i !== index);
        setInputFields(updatedFields);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("Submitting data:", { inputFields, email });

        try {
            const response = await axios.post(`${BaseUrl}/major`, { inputFields, email });
            console.log("Response data:", response.data);
            alert("Form submitted successfully!");

            setEmail("");
            setInputFields([{ emailAddress: '', fullName: '' }]);
        } catch (error) {
            console.error("Error submitting form:", error.response?.data || error.message);
            alert("Failed to submit the form.");
        }
    };

    return (
        <form onSubmit={handleSubmit}>

            {inputFields.map((field, index) => (
                <div key={index}>
                    <div className="row mt-5 mt-lg-3">
                        <div className="col-lg-6 ">
                            <div className="form_register1 right-block d-block ms-lg-auto">
                                <input
                                    type="email"
                                    name="emailAddress"
                                    className="form-control shadow-none  age_input"
                                    placeholder="Full Name"
                                    value={field.emailAddress}
                                    onChange={(e) => handleChange(index, e)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 mt-3 mt-lg-0">
                            <div className="form_register1 center-block d-block mx-auto mx-lg-0">
                                <input
                                    type="text"
                                    name="fullName"
                                    value={field.fullName}
                                    className="form-control  shadow-none age_input"
                                    placeholder="Email Address"
                                    onChange={(e) => handleChange(index, e)}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-6 col-6">
                            {inputFields.length !== 1 ? (
                                <button
                                    className="btn btn-outline-danger form_register2 shadow-none right-block d-block ms-auto"
                                    onClick={() => handleRemoveFields(index)}
                                >
                                    Remove Person
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col-lg-6 col-6">
                            <button
                                type="button"
                                className="btn btn-outline-success  form_register2 shadow-none"
                                onClick={handleAddFields}
                            >
                                Add Person
                            </button>
                        </div>
                    </div>
                </div>
            ))}

        </form>
    );
};

export default AccompanyPerson;

import React, { useEffect, useState } from "react";
import Accopany from "./Accopany";
import axios from "axios";
import { BaseUrl } from "../../utils/BaseUrl";

const CountAccompany = () => {
  const [CountAccompany, setCountAccompany] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${BaseUrl}/major`);
        setCountAccompany(res.data);
      } catch (err) { }
    };

    fetchData();
  }, []);

  console.log(CountAccompany);
  return (
    <div>
      {CountAccompany.map((item) => {
        // console.log(item.inputFields[item._id].fullName.length);
        return (
          <div>
            {item.inputFields.map((iu) => {
              console.log(iu.emailAddress.length);
              return (
                <>
                  <h1>{iu.emailAddress.length}</h1>
                </>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default CountAccompany;

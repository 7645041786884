import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../component/Footer/Footer";
import BasicExample from "../../component/Navbar/BasicExample";
import "./Register.css";

const RegisterDetail = () => {
  return (
    <div>
      <BasicExample />

      <div className="row">
        <div className="col-lg-12 col-12">
          <div className="validThings">
            <div>
              <h2 className="text-center details_head"> Registration</h2>

            </div>
          </div>
        </div>
      </div>


      {/* <div className="container-fluid col-10 mt-5">
        <div className="row ">
          <div className="col-lg-12 mt-4">
            <h2 className="conference_register">
              CONFERENCE REGISTRATION DECEMBER 1<sup>st</sup>
            </h2>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-4 col-md-12 col-12">
            <div className="package_main">
              <div>
                <h3 className="pacakgeDetail_head text-center">
                  UNDERGRADUATE STUDENT PACKAGE
                </h3>
                <p className="packageDetail_para text-center mt-5">
                  (Includes - Registration, Registration Gift Packs, Attend
                  Scientific Sessions, Present Paper & Poster, High Tea Snacks
                  for 2 Days, Lunch for 2 Days & Attend World Dental Awards in
                  Presidential Banquet Dinner on Internationaly Acclaimed Cruise on Sep 30<sup>th</sup> 2023)
                </p>
                <div className="container-fluid mt-5">
                  <div className="fixed_box">
                    <p className="price_chart">
                      INR ₹12,000+18% GST

                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main1">
              <div>
                <h3 className="pacakgeDetail_head text-center">
                  POSTGRADUATE STUDENT & DENTIST PACKAGE
                </h3>
                <p className="packageDetail_para text-center mt-5">
                  (Includes - Registration, Registration Gift Packs, Attend
                  Scientific Sessions, Present Paper & Poster, High Tea Snacks
                  for 2 Days, Lunch for 2 Days & Attend World Dental Awards in
                  Presidential Banquet Dinner on Internationaly Acclaimed Cruise on Sep 30<sup>th</sup> 2023.)
                </p>
                <div className="container-fluid mt-5">
                  <div className="fixed_box">
                    <p className="price_chart">
                      INR ₹20,000+18% GST
                     
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main2">
              <div>
                <h3 className="pacakgeDetail_head text-center">
                  FOR ACCOMPANYING PERSON (NON DENTIST)
                </h3>
                <p className="packageDetail_para text-center mt-5">
                  (Includes - Registration, Attend Scientific Sessions, High Tea
                  Snacks for 2 Days, Lunch for 2 Days & Attend World Dental
                  Awards in Presidential Banquet Dinner on Internationaly Acclaimed Cruise on Sep 30<sup>th</sup> 2023)
                </p>
                <div className="container-fluid mt-5">
                  <div className="fixed_box">
                    <p className="price_chart">
                      INR ₹16,000+18% GST 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid col-10 mt-5">
        <div className="row ">
          <div className="col-lg-12 mt-4">
            <h2 className="conference_register">
              CONFERENCE REGISTRATION DECEMBER 1<sup>st</sup>
            </h2>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-3 col-md-12 col-12">
            <div className="package_main">
              <div>
                <h3 className="pacakgeDetail_head text-center">
                  UNDERGRADUATE STUDENT PACKAGE:
                </h3>
                <p className="packageDetail_para text-center mt-5 px-1">
                  Includes - Registration, Registration Gift Packs, Attend
                  Scientific Sessions, Present Paper & Poster, High Tea Snacks
                  for 2 Days, Lunch for 2 Days & Attend World Dental Awards in
                  Presidential Banquet Dinner.
                </p>
                <div className="container-fluid mt-5">
                  <div className="fixed_box">
                    <p className="price_chart">
                      INR ₹12,000 + 18% GST

                    </p>
                    <Link to="/registration" className="linkALL">
                      <button
                        type="button"
                        className="btn_details center-block d-block mx-auto my-2"
                      >
                        Register
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main1 ">
              <div>
                <h3 className="pacakgeDetail_head text-center">
                  POSTGRADUATE STUDENT & DENTIST PACKAGE:
                </h3>
                <p className="packageDetail_para text-center mt-5 px-1">
                  Includes - Registration, Registration Gift Packs, Attend
                  Scientific Sessions, Present Paper & Poster, High Tea Snacks
                  for 2 Days, Lunch for 2 Days & Attend World Dental Awards in
                  Presidential Banquet Dinner.
                </p>
                <div className="container-fluid mt-5">
                  <div className="fixed_box">
                    <p className="price_chart">
                      INR ₹20,000 + 18% GST

                    </p>
                    <Link to="/registration" className="linkALL">
                      <button
                        type="button"
                        className="btn_details center-block d-block mx-auto my-2"
                      >
                        Register
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main2">
              <div>
                <h3 className="pacakgeDetail_head text-center">
                  FOR ACCOMPANYING PERSON (NON DENTIST)
                </h3>
                <p className="packageDetail_para text-center mt-5 px-1">
                  Includes - Registration, Attend Scientific Sessions, High Tea
                  Snacks for 2 Days, Lunch for 2 Days & Attend World Dental
                  Awards in Presidential Banquet Dinner.
                </p>
                <div className="container-fluid mt-5">
                  <div className="fixed_box">
                    <p className="price_chart">
                      INR ₹16,000 + 18% GST

                    </p>
                    <Link to="/registration" className="linkALL">
                      <button
                        type="button"
                        className="btn_details center-block d-block mx-auto my-2"
                      >
                        Register
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main2">
              <div>
                <h3 className="pacakgeDetail_head text-center">
                  DELEGATES FROM OUTSIDE INDIA
                </h3>
                {/* <p className="packageDetail_para text-center mt-5">
                  Includes - Registration, Attend Scientific Sessions, High Tea
                  Snacks for 2 Days, Lunch for 2 Days & Attend World Dental
                  Awards in Presidential Banquet Dinner and Till November 23000+ GST and December 1st 25000+ GST.
                </p> */}
                <div className="container-fluid mt-5">
                  <div className="fixed_box">
                    <p className="price_chart">

                      USD $ 260 + 18% GST
                    </p>
                    <Link to="/registration" className="linkALL">
                      <button
                        type="button"
                        className="btn_details center-block d-block mx-auto my-2"
                      >
                        Register
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid col-10 mt-5">
        <div className="row ">
          <div className="col-lg-12 mt-4">
            <h2 className="conference_register">
              CONFERENCE REGISTRATION FROM DECEMBER 2<sup>nd</sup>
            </h2>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-3 col-md-12 col-12">
            <div className="package_main">
              <div>
                <h3 className="pacakgeDetail_head text-center">
                  UNDERGRADUATE STUDENT PACKAGE:
                </h3>
                <p className="packageDetail_para text-center mt-5 px-1">
                  Includes - Registration, Registration Gift Packs, Attend
                  Scientific Sessions, Present Paper & Poster, High Tea Snacks
                  for 2 Days, Lunch for 2 Days & Attend World Dental Awards in
                  Presidential Banquet Dinner.
                </p>
                <div className="container-fluid mt-5">
                  <div className="fixed_box">
                    <p className="price_chart">
                      INR ₹13,000 + 18% GST
                    </p>
                    <Link to="/registration" className="linkALL">
                      <button
                        type="button"
                        className="btn_details center-block d-block mx-auto my-2"
                      >
                        Register
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main1 ">
              <div>
                <h3 className="pacakgeDetail_head text-center">
                  POSTGRADUATE STUDENT & DENTIST PACKAGE:
                </h3>
                <p className="packageDetail_para text-center mt-5 px-1">
                  ( Includes - Registration, Registration Gift Packs, Attend
                  Scientific Sessions, Present Paper & Poster, High Tea Snacks
                  for 2 Days, Lunch for 2 Days & Attend World Dental Awards in
                  Presidential Banquet Dinner.)
                </p>
                <div className="container-fluid mt-5">
                  <div className="fixed_box">
                    <p className="price_chart">
                      INR ₹21,000 + 18% GST

                    </p>
                    <Link to="/registration" className="linkALL">
                      <button
                        type="button"
                        className="btn_details center-block d-block mx-auto my-2"
                      >
                        Register
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main2">
              <div>
                <h3 className="pacakgeDetail_head text-center">
                  FOR ACCOMPANYING PERSON (NON DENTIST)
                </h3>
                <p className="packageDetail_para text-center mt-5 px-1">
                  Includes - Registration, Attend Scientific Sessions, High Tea
                  Snacks for 2 Days, Lunch for 2 Days & Attend World Dental
                  Awards in Presidential Banquet Dinner.
                </p>
                <div className="container-fluid mt-5">
                  <div className="fixed_box">
                    <p className="price_chart">
                      INR ₹17,000 + 18% GST

                    </p>
                    <Link to="/registration" className="linkALL">
                      <button
                        type="button"
                        className="btn_details center-block d-block mx-auto my-2"
                      >
                        Register
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-12 mt-lg-0 mt-5">
            <div className="package_main2">
              <div>
                <h3 className="pacakgeDetail_head text-center">
                  DELEGATES FROM OUTSIDE INDIA
                </h3>
                {/* <p className="packageDetail_para text-center mt-5">
                  Includes - Registration, Attend Scientific Sessions, High Tea
                  Snacks for 2 Days, Lunch for 2 Days & Attend World Dental
                  Awards in Presidential Banquet Dinner and Till November 23000+ GST and December 1st 25000+ GST.
                </p> */}
                <div className="container-fluid mt-5">
                  <div className="fixed_box">
                    <p className="price_chart">

                      USD $ 260  + 18% GST
                    </p>
                    <Link to="/registration" className="linkALL">
                      <button
                        type="button"
                        className="btn_details center-block d-block mx-auto my-2"
                      >
                        Register
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </div>
  );
};

export default RegisterDetail;

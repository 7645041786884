import { useContext, useEffect, useRef, useState } from "react";
import Footer from "../component/Footer/Footer";
import BasicExample from "../component/Navbar/BasicExample";
import { Context } from "../context/Context";
import "./Abstract.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import axios from "axios";
import Select from "react-select";
import { useSelector } from "react-redux";
import { subjectofPresent } from "../utils/constants";
import { usersId } from "../slices/LoginSlice";
import { BaseUrl } from "../utils/BaseUrl";

export default function AbstractUpdated() {

  const currentUsersId = useSelector(usersId);

  console.log(currentUsersId)

  const [file, setFile] = useState(null);
  const [isFetching, setISFetching] = useState(false)

  const [success, setSuccess] = useState(false);
  const [result, setResult] = useState(null);

  const [biographyFiles, setBiography] = useState("");
  const [Quality, setQuality] = useState("");
  const [subjectofPresentation, setsubjectofPresentation] = useState("");

  const [Presentation, setPresentation] = useState(
    "Paper Presentation for Students"
  );
  const [Speak, setSpeak] = useState("");
  const userData = useSelector((state) => state.LoginSlice.LoginData);
  const userName = userData[0]?.details?.name;


  useEffect(() => {
    setsubjectofPresentation(Quality.value);
  });

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const onOptionChange = (e) => {
    setPresentation(e.target.value);
  };

  const onOptionChange1 = (e) => {
    setSpeak(e.target.value);
  };

  console.log(biographyFiles);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setISFetching(true)
    const updatedUser = {
      userId: currentUsersId,
      biographyFiles,
      Presentation,
      Speak,
      subjectofPresentation,
    };


    console.log(updatedUser)

    if (file) {
      const data = new FormData();
      const filename = file.name;
      data.append("name", file.name);
      data.append("file", file);
      updatedUser.UploadFile = filename;
      setResult(file.name);
      try {
        const res = await axios.post(
          `${BaseUrl}/api/document`,
          data
        );
        console.log(res.data);
      } catch (err) { }
    }
    try {
      const res = await axios.put(
        `${BaseUrl}/routes/` + currentUsersId,
        updatedUser
      );
      setSuccess(true);
      setISFetching(false);
      alert("Abstract Submitted Successfully");
      res.data && (window.location.href = "https://worlddentalconference.in/");
    } catch (err) {
      console.log(err);
    }

    setBiography("");
    setFile("");
  };

  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const fileInputRef = useRef();
  const PF = `${BaseUrl}/abstract/`;

  const handleclicks = () => {
    setSpeak("");
  }


  return (
    <>
      <BasicExample />
      {isFetching && <div class="loader"></div>}
      <div className="settings">

        <div className="abstarct_updated_banner">
          <div className="row">
            <div className="col-lg-12 col-12">
              <h2 className="abstract_headed">Abstract / Poster Submission</h2>
            </div>
          </div>
        </div>

        <div className="container-fluid col-10 mt-5">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <h2 className="userAbstract">Hi {userName},</h2>
            </div>
          </div>
        </div>

        <form className="settingsForm" onSubmit={handleSubmit}>
          <div className="container-fluid col-lg-7 col-10 mt-5">
            <div className="row mt-5">
              <div className="col-lg-4 col-4 ">
                <Form.Check
                  type="radio"
                  name="Paper Presentation for Students"
                  value="Paper Presentation for Students"
                  label="Paper Presentation for Students"
                  id="regular"
                  checked={Presentation === "Paper Presentation for Students"}
                  onChange={onOptionChange}
                  onClick={handleclicks}
                  className="center-block d-block mx-auto"
                />
              </div>

              <div className="col-lg-4 col-4 ">
                <Form.Check
                  type="radio"
                  name="Poster Presentation for Students"
                  value="Poster Presentation for Students"
                  id="medium"
                  label="Poster Presentation for Students"
                  checked={Presentation === "Poster Presentation for Students"}
                  onChange={onOptionChange}
                  onClick={handleclicks}
                  className="center-block d-block mx-auto"
                />
              </div>

              <div className="col-lg-4 col-4 center-block d-block mx-auto">
                <Form.Check
                  type="radio"
                  name="Abstract Submission for Speaker"
                  value="Abstract Submission for Speaker"
                  id="medium"
                  label="Abstract Submission for Speaker"
                  checked={Presentation === "Abstract Submission for Speaker"}
                  onChange={onOptionChange}
                  className="center-block d-block mx-auto"
                />
              </div>
            </div>
          </div>

          {Presentation === "Abstract Submission for Speaker" ? (
            <>
              <div className="container-fluid col-lg-7 col-10">
                <div className="row mt-5">
                  <div className="col-lg-4 col-4 ">
                    <Form.Check
                      type="radio"
                      name="Keynote Lecture"
                      value="Keynote Lecture"
                      label="Keynote Lecture"
                      id="Keynote1"
                      checked={Speak === "Keynote Lecture"}
                      onChange={onOptionChange1}
                      className="center-block d-block mx-auto"
                    />
                    <p className="Abstracted_para_updated mx-lg-3 mt-2">
                      <i className="bi bi-info-circle"> </i> Keynote Lecture (More
                      Than 15 years of experience after MDS completion)
                    </p>
                  </div>

                  <div className="col-lg-4 col-4 ">
                    <Form.Check
                      type="radio"
                      name="Guest Lecture"
                      value="Guest Lecture"
                      id="Guest"
                      label="Guest Lecture"
                      checked={Speak === "Guest Lecture"}
                      onChange={onOptionChange1}
                      className="center-block d-block mx-auto"
                    />
                    <p className="Abstracted_para_updated mx-lg-3 mt-2">
                      <i className="bi bi-info-circle"> </i> Guest Lecture (More
                      than 5 years of experience after MDS completion)
                    </p>
                  </div>

                  <div className="col-lg-4 col-4 center-block d-block mx-auto">
                    <Form.Check
                      type="radio"
                      name="Rising Star Lecture"
                      value="Rising Star Lecture"
                      id="Rising"
                      label="Rising Star Lecture"
                      checked={Speak === "Rising Star Lecture"}
                      onChange={onOptionChange1}
                      className="center-block d-block mx-auto"
                    />
                    <p className="Abstracted_para_updated mx-lg-3 mt-2">
                      <span>
                        <i className="bi bi-info-circle"> </i>
                      </span>

                      <span>
                        Rising Star Lecture (Less than 5 years of experience after
                        MDS completion)
                      </span>
                    </p>
                  </div>

                  <div className="col-lg-12 col-12 mt-5">
                    <div className="form_register_updated1 center-block d-block mx-auto">
                      <Select
                        placeholder="Subject of Presentation..."
                        options={subjectofPresent}
                        isSearchable
                        label={Quality}
                        defaultValue={Quality}
                        required
                        onChange={setQuality}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid col-lg-10 col-12">
                <div className="row mt-5">
                  <div className="col-lg-12">
                    <Form.Group className="mb-3 ">
                      <div className="fileBlessing mt-4">
                        <Form.Control
                          type="file"
                          id="fileInput"
                          placeholder="Upload a Passport Size Photo"
                          className="file_matter shadow-none"
                          onChange={handleFileChange}
                          ref={fileInputRef}
                          name="Upload a Passport Photo"
                          accept="application/pdf"
                          required
                        />
                      </div>
                      {isSuccess ? (
                        <p className="success-message mt-3">
                          Validation successful
                        </p>
                      ) : null}
                      <p className="error-message mt-3">{errorMsg}</p>

                      {Presentation === "Poster Presentation for Students" ? (
                        <p className="infos_para file_setting mt-3">
                          <i className="bi bi-info-circle"> </i> Poster Submission
                          (To Attach in PDF Format)
                        </p>
                      ) : (
                        <p className="infos_para file_setting mt-3">
                          <i className="bi bi-info-circle"> </i>Abstract
                          submission (Not more than 250 words)
                        </p>
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-lg-12 mt-4">
                    <div className="form_register center-block d-block mx-auto">
                      <Form.Group className="mb-3 ">
                        <textarea
                          className="form-control textarea_abstract"
                          onChange={(e) => setBiography(e.target.value)}
                          placeholder={
                            userData[0]?.details?.biography
                              ? userData[0]?.details?.biography
                              : "Enter Your Biography"
                          }
                          id="floatingTextarea"
                        ></textarea>
                        <p className="error-message mt-3">{errorMsg}</p>

                        <p className="infos_para  mt-3">
                          <i className="bi bi-info-circle"> </i>Not more than 250
                          words
                        </p>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="container-fluid col-lg-10 col-12">
                <div className="row mt-5">
                  <div className="col-lg-12 col-12 mt-5">
                    <div className="form_register_updated2 center-block d-block mx-auto">
                      <Select
                        placeholder="Subject of Presentation..."
                        options={subjectofPresent}
                        isSearchable
                        label={Quality}
                        defaultValue={Quality}
                        required
                        onChange={setQuality}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mt-5">
                    <Form.Group className="mb-3 ">
                      <div className="fileBlessing mt-4">
                        <Form.Control
                          type="file"
                          id="fileInput"
                          placeholder="Upload a Passport Size Photo"
                          className="file_matter shadow-none"
                          onChange={handleFileChange}
                          ref={fileInputRef}
                          name="Upload a Passport Photo"
                          accept="application/pdf"
                          required
                        />
                      </div>
                      {isSuccess ? (
                        <p className="success-message mt-3">
                          Validation successful
                        </p>
                      ) : null}
                      <p className="error-message mt-3">{errorMsg}</p>
                      {Presentation === "Poster Presentation for Students" ? (
                        <p className="infos_para file_setting mt-3">
                          <i className="bi bi-info-circle"> </i> Poster Submission
                          (To Attach in PDF Format)
                        </p>
                      ) : (
                        <p className="infos_para file_setting mt-3">
                          <i className="bi bi-info-circle"> </i>Abstract
                          submission (Not more than 250 words)
                        </p>
                      )}
                    </Form.Group>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="container-fluid col-lg-10 col-12">
            <div className="row">
              <div className="col-lg-12">
                <Button
                  type="submit"
                  className="mt-4 submit_Form form_register2 shadow-none center-block d-block mx-auto"
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>
        </form>

        <Footer />
      </div>
    </>
  );
}

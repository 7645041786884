import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LoginUser } from "../../slices/LoginSlice";

const LoginSample = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    console.log(email, password);
    dispatch(LoginUser({ email: email, password: password }));
  };

  return (
    <div>
      <label>name</label>
      <input type="email" onChange={(e) => setEmail(e.target.value)} />
      <label>password</label>
      <input type="password" onChange={(e) => setPassword(e.target.value)} />
      <button type="button" onClick={handleSubmit}>
        submit
      </button>
    </div>
  );
};

export default LoginSample;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context1 } from "../../context1/Context";
import AdminNavbar from "../../component/Navbar/AdminNavbar";
import Registration from "../../assests/Home/Rectangle12.png";
import Dental from "../../assests/Home/Rectangle11.png";
import Footer from "../../component/Footer/Footer";
import "./Admin.css";
import { BaseUrl } from "../../utils/BaseUrl";


const UserDetail = () => {
  const navigate = useNavigate();

  const [state, setState] = useState("");
  const [AwardCounts, setAwardCounts] = useState("");
  const [Accompany, setAccompany] = useState("");
  const [Accompanys, setAccompanys] = useState("");

  const { dispatch } = useContext(Context1);

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const handleClick1 = () => {
    navigate("/accompany");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${BaseUrl}/routes/countRegister`
        );
        setState(res.data);
      } catch (err) { }

      try {
        const res = await axios.get(`${BaseUrl}/award/award`);
        setAwardCounts(res.data);
      } catch (err) { }

      try {
        const res = await axios.get(`${BaseUrl}/major/email`);
        setAccompany(res.data);
      } catch (err) { }

      try {
        const res = await axios.get(`${BaseUrl}/major/`);
        setAccompanys(res.data);
      } catch (err) { }
    };

    fetchData();
  }, []);

  return (
    <div>
      <AdminNavbar />

      <div className="container-fluid col-10 mt-5 pb-5">
        <div className="row">
          <div className="col-lg-12 col-12">
            <h3 className="register_detail_head mt-5 mt-lg-0">Hi Admin,</h3>
          </div>
        </div>
      </div>

      <div className="container-fluid col-10 ">
        <div className="row mt-5 ">
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <div>
              <Link to="/registrationDetail" className="linkALL">
                <div className="Registration_img">
                  <img
                    src={Registration}
                    className="img-fluid rectangle_images"
                    alt="" />
                  <h1 className="registration_head1 text-center pt-4 pb-4">
                    <b>Registration Details</b>
                    <br />
                    Total Count: &nbsp; {state}
                    <span className="visibility_none">Speaker Counts:</span>
                  </h1>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <div>
              <Link to="/accompany" className="linkALL">
                <div className="Registration_img">
                  <img src={Dental} className="img-fluid rectangle_images" alt="" />
                  <h1 className="registration_head1 text-center pt-4 pb-4">
                    <b>Accompany Details</b> <br />
                    Total Count: &nbsp; {Accompany}
                    <span className="visibility_none">Speaker Counts:</span>
                  </h1>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
            <div>
              <Link to="/detailAwards" className="linkALL">
                <div className="Registration_img">
                  <img src={Dental} className="img-fluid rectangle_images" alt="" />
                  <h1 className="registration_head1 text-center pt-4 pb-4">
                    <b>Dental Award Details</b>
                    <br />
                    Total Count: &nbsp; {AwardCounts}
                    <br />
                    <span className="visibility_none">
                      Speaker Counts: &nbsp;
                    </span>
                  </h1>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {/* <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Abstract DowLoad Link
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {state.map((item) => {
              return (
                <>
                  <Dropdown.Item
                    href={`https://wdc-backend.onrender.com/abstract/${item.UploadFile}`}
                  >
                    {item.email}
                  </Dropdown.Item>
                </>
              );
            })}
          </Dropdown.Menu>
        </Dropdown> */}
      </div>

      <div className="row pt-5">
        <div className="col-lg-12 col-12 pt-5">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default UserDetail;

import Logo from "../../assests/WDClogo.png";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  useNavigate,
  Link,
} from "react-router-dom";
import "./Navbarss.css";
import Nav from "react-bootstrap/Nav";
import Hamburger from "../../assests/hamburger.png";
import Close from "../../assests/closecircle.png";
import { useContext, useState } from "react";
import { Context } from "../../context/Context";
import { Context1 } from "../../context1/Context";

function AdminNavbar() {
  const navigate = useNavigate();

  const [container, setContainer] = useState(false);

  const { users, dispatch } = useContext(Context1);

  const openClick = () => {
    setContainer(true);
  };

  const closeClick = () => {
    setContainer(false);
  };

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  return (
    <>
      <div className="mobile_container">
        <div
          className={` ${container ? "header_mobile menu-open" : "header_mobile"}`}
        >
          <div className="container-fluid col-10">
            <div className="d-flex justify-content-between align-items-center mobile_pallete">
              <div>
                <a href="./index.html">
                  <img src={Logo} className="img-fluid  logo ml-5" />
                </a>
              </div>
              <div className="icon-container mr-5">
                <img
                  src={Hamburger}
                  className="img-fluid hamburger"
                  onClick={openClick}
                />
                {/* <img src="" className="img-fluid closed"/> */}
              </div>
            </div>
          </div>
          <div className="mobile-menu">
            <ul className="menu">
              <li className="menu-item">
                <NavLink to="/" className="mx-3">
                  Home
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/commitee" className="mx-3 ">
                  Commitee
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/registerDetail" className="mx-3 ">
                  Registration
                </NavLink>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle menu-item"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Abstract Submission
                </a>
                <ul
                  className="dropdown-menu menu-item"
                  aria-labelledby="navbarDropdown"
                >
                  <Link
                    to="/abstractUpdated"
                    className="dropdown-item menu-item"
                  >
                    Submission
                  </Link>
                  <Link to="/abstract" className="dropdown-item menu-item">
                    Instruction
                  </Link>
                </ul>
              </li>
              <li className="menu-item">
                <NavLink to="/awardDetail" className="mx-3 ">
                  Awards
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/speakers" className="mx-3 ">
                  Speakers
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/contact" className="mx-3 ">
                  Contact Us
                </NavLink>
              </li>
              <li className="menu-item">
                {!users ? (
                  ""
                ) : (
                  <Link to="/contact" className="mx-3" onClick={handleLogout}>
                    Logout
                  </Link>
                )}
              </li>

              <li className="menu-item">
                <div className="icon-container1 mt-4">
                  <img
                    src={Close}
                    className="img-fluid closed "
                    onClick={closeClick}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <nav className="Navbar navbar navbar-expand-lg navbar-light bg-light sticky-top">
        <div className="container-fluid col-11">
          <NavLink to="/">
            <img src={Logo} className="img-fluid navbar_logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 ">
              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/" className="mx-3 Navbar_text">
                  Home
                </NavLink>
              </li>
              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/commitee" className="mx-3 Navbar_text">
                  Commitee
                </NavLink>
              </li>
              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/registerDetail" className="mx-3 Navbar_text">
                  Registration
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle "
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Abstract Submission
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link to="/abstractUpdated" className="dropdown-item ">
                    Submission
                  </Link>
                  <Link to="/abstract" className="dropdown-item ">
                    Instruction
                  </Link>
                </ul>
              </li>
              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/awardDetail" className="mx-3 Navbar_text">
                  Awards
                </NavLink>
              </li>
              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/speakers" className="mx-3 Navbar_text">
                  Speakers
                </NavLink>
              </li>
              <li className="nav-item navbar_logo_expensive">
                <NavLink to="/contact" className="mx-3 Navbar_text">
                  Contact Us
                </NavLink>
              </li>
            </ul>
            <form className="d-flex">
              {!users ? (
                ""
              ) : (
                <Link
                  to="/"
                  className="mx-3 Navbar_text1"
                  onClick={handleLogout}
                >
                  Logout
                </Link>
              )}
            </form>
          </div>
        </div>
      </nav>
    </>
  );
}

export default AdminNavbar;

import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    this.getTimeDifference(this.props.eventDate);
    setInterval(() => this.getTimeDifference(this.props.eventDate), 1000);
  }

  leadingZero(num) {
    return num < 10 && num > 0 ? "0" + num : num;
  }

  getTimeDifference(eventDate) {
    const time = Date.parse(eventDate) - Date.parse(new Date());
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    this.setState({ days, hours, minutes, seconds });
  }

  render() {
    return (
      <div>
        <div className="clock d-flex justify-content-center align-items-center">
          <p className="text-center mx-lg-4 mx-2 timer_para">
            <p style={{ marginBottom: "0rem" }}>
              <span className="Special_class">
                {this.leadingZero(this.state.days)}
              </span>{" "}
              <br />
              <span className="Special_Date">
                {this.state.days === 1 ? "Day" : "Days"}
              </span>
            </p>
          </p>

          <p className="text-center mx-lg-4 mx-2 timer_para">
            <p style={{ marginBottom: "0rem" }}>
              <span className="Special_class">
                {this.leadingZero(this.state.hours)}
              </span>{" "}
              <br />
              <span className="Special_Date">
                {" "}
                {this.state.hours === 1 ? "hour" : "hours"}
              </span>
            </p>
          </p>

          <p className="text-center mx-lg-4 mx-2 timer_para">
            <p style={{ marginBottom: "0rem" }}>
              <span className="Special_class">
                {this.leadingZero(this.state.minutes)}
              </span>
              <br />
              <span className="Special_Date">
                {this.state.minutes === 1 ? "minute" : "minutes"}
              </span>
            </p>
          </p>

          <p className="text-center mx-lg-4 mx-2 timer_para">
            <p style={{ marginBottom: "0rem" }}>
              <span className="Special_class">
                {this.leadingZero(this.state.seconds)}
              </span>{" "}
              <br />
              <span className="Special_Date">
                {this.state.seconds === 1 ? "second" : "seconds"}
              </span>
            </p>
          </p>
          <Link to="/registerDetail" className="linkALL">
            <button type="button" className="btn_Register">
              Register
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

class Sample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [{ name: "The Singularity", date: "September 30, 2023" }],
      newDate: "",
      newName: "",
    };
  }

  addNewEvent = () => {
    let evt = { name: this.state.newName, date: this.state.newDate };
    let newEvents = this.state.events.concat(evt);
    this.setState({
      newDate: "",
      newName: "",
      events: newEvents,
    });
  };

  render() {
    let events = this.state.events.map((evt) => (
      <Timer key={evt.date} eventName={evt.name} eventDate={evt.date} />
    ));
    return <div className="App">{events}</div>;
  }
}

export default Sample;

import React from "react";
import Footer from "../../component/Footer/Footer";
import BasicExample from "../../component/Navbar/BasicExample";
import "./Contact.css";
import worldImg from "../../assests/contact/contactField.png";
import logo from "../../assests/contact/logo.png";
import logos from "../../assests/contact/logo1.png";

const Contact = () => {
  return (
    <div>
      <BasicExample />

      <div className="contact_background pt-5 pb-5">
        <div className="container-fluid col-lg-12 col-12">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <h2 className="contact_head">CONTACT</h2>
              <p className="mt-4 contacts_para">
                Please feel free to contact us at any time with any questions or
                concerns you may have. We will do our best to respond to your
                inquiry as quickly as possible.
              </p>
              <img
                src={worldImg}
                className="img-fluid world_contact_img mt-5 center-block d-block mx-lg-0 mx-auto mt-lg-4"
              />
              <h2 className="mt-4 bangok_head">BINTANG BALI RESORT </h2>
              <div className="d-flex align-items-center justify-content-center mt-lg-4 mt-5">
                <div>
                  <p className="organized">organised by:</p>
                </div>
                <div>
                  <img src={logo} className="img-fluid contact_logo mx-2" />
                </div>
                <div>
                  <img src={logos} className="img-fluid contact_logo mx-2" />
                </div>
              </div>
            </div>

            {/* <div className="col-lg-6 col-md-6 col-12 ">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.6750818904497!2d80.10298727582618!3d12.928592615818225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525fb04f9981d1%3A0xd538069bcd74be65!2sJOKAR%20Creations%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1684860245834!5m2!1sen!2sin"
                className="map mt-lg-0 mt-5 center-block d-block mx-auto mx-lg-0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div> */}
          </div>

          <div className="places_back mt-5">
            <div className="row mt-5 d-flex justify-content-center align-items-center">


              <div className="col-lg-8 col-md-12 col-12 mt-lg-0 mt-5 center-block d-block mx-auto">
                <h2 className="places_head">FOR MORE DETAILS CONTACT</h2>

                <div className=" row d-flex align-items-center justify-content-around mt-5">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                        Dr. A.V. Arun (Organizing Chairman)
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      {" "}
                      +91 9884042526
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+91988404252" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div>

                <div className=" row d-flex align-items-center justify-content-around mt-lg-3 mt-5">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                        Dr. A.V. Arun (Organizing Chairman)
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      {" "}
                      +91 9884042526
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+919884042526" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div>

                <div className="row d-flex align-items-center justify-content-around mt-lg-3 mt-5">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                        Dr. A. Nayeemullah Khan (Organizing Secretary)
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      {" "}
                      +91 7010186335
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+917010186335" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div>

                <div className="row d-flex align-items-center justify-content-around mt-3">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                        Dr. Ankit Sikri (Joint Secretary){" "}
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      {" "}
                      +91 75081 56778
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+917508156778" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div>

                <div className="row d-flex align-items-center justify-content-around mt-3">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                        Dr. Rakshakan Vikraman (Head of Tourism & Hospitality){" "}
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      +91 9841990565
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+919841990565" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div>

                <div className="row d-flex align-items-center justify-content-around mt-3">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                        Dr. Nashra Kareem (Scientific Head){" "}
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      {" "}
                      +91 9940305545
                    </p>
                  </div>

                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+919940305545" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contact;
